import { API, setAuthToken } from "config/API.js";
import { GET_DISTRICT } from "../constant.js";

export const getDistrict = (body) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.post(`/district`, body)
            .then((res) => {
                dispatch({
                    type: GET_DISTRICT,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DISTRICT,
                    payload: [],
                });
            });
    };
};