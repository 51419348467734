import { API, setAuthToken } from "config/API.js";
import {
    GET_LOGISTIK_SATUAN_UNIT,
} from "../constant.js";
export const getDataLogistikSatuanUnit = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/kategori/unit?${new URLSearchParams(params)}`)
            .then((res) => {
                dispatch({
                    type: GET_LOGISTIK_SATUAN_UNIT,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_LOGISTIK_SATUAN_UNIT,
                    payload: [],
                });
            });
    };
};

export const addLogistikSatuanUnit = (body) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/unit/tambah", body);
};

export const updateLogistikSatuanUnit = (body) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/unit/update", body);
};

export const deleteLogistikSatuanUnit = (code) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/unit/delete", code);
};