import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  createStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components";
import { LOGIN_ERROR, loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Swal from "sweetalert2";

const useStyles = makeStyles(({ palette, ...theme }) =>
  createStyles({
    logo: {
      maxWidth: 188,
      marginBottom: 30,
    },
    containerForm: {
      padding: "1rem",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 3rem",
      },
    },
    bgPage: {
      backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
      backgroundRepeat: "repeat",
    },
  })
);

const Login = ({ history }) => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [usernameErrorText, setUsernameErrorText] = React.useState("");
  const [passwordErrorText, setPasswordErrorText] = React.useState("");
  const [loginErrorText, setLoginErrorText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = useState({
    usersname: "",
    password: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await setLoading(true);
    if (!params.usersname) {
      setUsernameErrorText("Please enter username");
    }
    // else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(params.email)
    // ) {
    //   setUsernameErrorText("Invalid email address");
    //   setLoading(false);
    //   return;
    // }
    else {
      setUsernameErrorText("");
    }
    if (!params.password) {
      setPasswordErrorText("Please enter password");
    } else {
      setPasswordErrorText("");
    }

    if (params.usersname && params.password) {
      dispatch(loginWithEmailAndPassword(params)).then(async (res) => {
        console.log(res);
        await setLoading(false);
        if (res.type === LOGIN_ERROR) {
          let error = res?.payload?.data;
          Swal.fire({
            title: "Oopss!",
            text:
              error?.code === 2
                ? error?.message
                : "username or password incorrect",
            imageUrl: "/assets/images/icons/ic_error.svg",
            imageWidth: 92,
            imageHeight: 92,
            timer: 2000,
            confirmButtonText: "OK",
          });
        } else {
          window.location.href = "/dashboard";
        }
      });
    }

    await setLoading(false);
  };

  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0 auto",
      }}
    >
      <Card
        style={{
          maxWidth: "535px",
          maxHeight: "531px",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loginErrorText && (
          <Alert severity="error" className="mb-4">
            {loginErrorText}
          </Alert>
        )}
        <Link to="/">
          <img
            src="/assets/images/logos/logo.png"
            alt="Kenalan"
            className={classes.logo}
          />
        </Link>
        <Grid container spacing={0} justifyContent="center">
          <Grid item sm={12} xs={12} lg={12} md={12}>
            <Box component="form" autoComplete="off">
              <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                <label
                  htmlFor="outlined-basic"
                  className="mb-2 fw-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Username
                </label>
                <TextField
                  fullWidth
                  hiddenLabel
                  type="text"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Masukan username"
                  name="usersname"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <FormHelperText
                  id="component-error-text"
                  className="text-danger"
                >
                  {usernameErrorText}
                </FormHelperText>
              </FormControl>
              <FormControl className="w-full" style={{ width: "100%" }}>
                <label
                  htmlFor="outlined-basic"
                  className="mb-2 fw-bold"
                  style={{ fontWeight: "bold" }}
                >
                  Password
                </label>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Masukan password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff htmlColor="#4D5B7C" />
                          ) : (
                            <Visibility htmlColor="#4D5B7C" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText
                  id="component-error-text"
                  className="text-danger"
                >
                  {passwordErrorText}
                </FormHelperText>
              </FormControl>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={<Checkbox name="remember" />}
                  label="Ingat Saya"
                  className="mb-3"
                />
                {/* <a
                  href="/forgot-password"
                  className="text-decoration-none fw-bold text-bold-primary"
                  style={{ color: "#00ACEE", fontWeight: "bold" }}
                >
                  Lupa Password?
                </a> */}
              </div>

              <Grid container>
                <CustomButton
                  text={loading ? <CircularProgress /> : "Masuk"}
                  fw={true}
                  onClick={handleSubmit}
                  disabled={loading}
                  bgColor="#00ACEE"
                  color="#FFFFFF"
                />
              </Grid>
              {/* <p className="text-center mt-3" style={{ fontWeight: "bold" }}>
                Belum punya akun?{" "}
                <a href="/register" style={{ color: "#00ACEE" }}>
                  Daftar disini
                </a>
              </p> */}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Login;
