/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Hidden,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuActions from "app/components/button/MenuActions";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  deletePengguna,
  getDataPengguna,
} from "app/redux/actions/PengaturanActions";
import Swal from "sweetalert2";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "app/components/sweetalertService";

const Pengguna = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
  });
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [10, 50, 100, 500];

  const { dataPengguna, dataPenggunaPaginate } = useSelector(
    (state) => state.pengaturan
  );

  const getData = (page = 1, limit = 10, search = state.search) => {
    dispatch(getDataPengguna({ page, limit, search }));
  };

  const handleSetRowsPerPage = (e) => {
    // console.log(e);
    e.persist();
    setRowsPerPage(e.target.value);

    let limit = e.target.value * state.page;
    if (state.page === 0) {
      limit = e.target.value;
    }

    getData(1, limit);
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (code) => {
    const confirmed = await showConfirmation("Hapus", "Apakah kamu yakin ?");

    if (confirmed) {
      try {
        deletePengguna(code).then((res) => {
          showSuccess("Data berhasil dihapus!");
          getData(1, rowsPerPage);
        });
      } catch (error) {
        showError("Data gagal dihapus !");
      }
    } else {
      showError("Data gagal dihapus !");
    }

    // Swal.fire({
    //   title: "Hapus",
    //   text: "Apakah kamu yakin",
    //   showCancelButton: true,
    //   confirmButtonText: "Yakin",
    //   cancelButtonText: "Batal",
    //   icon: "warning",
    //   buttonsStyling: false,
    //   reverseButtons: true,
    //   customClass: {
    //     confirmButton: "custom-success-button",
    //     cancelButton: "custom-error-button",
    //   },
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     deletePengguna(code).then((res) => {
    //       Swal.fire({
    //         title: '<div class="custom-swal-title">Data berhasil dihapus</div>',
    //         icon: "success",
    //         buttonsStyling: false,
    //         customClass: {
    //           confirmButton: "custom-success-button",
    //         },
    //       });
    //       getData(1, rowsPerPage);
    //     });
    //   }
    // });
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      getData(undefined, undefined, search);
    }
  };

  useEffect(() => {
    if (dataPengguna.length > 0) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }
  }, [dataPengguna]);

  return (
    <>
      <Card className="w-100 overflow-auto p-24" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-2 d-flex align-items-center justify-content-between"
        >
          {/* Mobile Layout */}
          <Hidden smUp>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Link to="/pengaturan/pengguna/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Baru"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="items-center background-white w-full"
                  placeholder={"Cari"}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleSearch}
                  value={search}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Desktop Layout */}
          <Hidden xsDown>
            <Grid item xs={12} sm className="flex flex-end">
              <Link to="/pengaturan/pengguna/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Baru"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
        </Grid>

        <TableContainer>
          <Table style={{ minWidth: 700 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  No
                </TableCell>
                <TableCell align="left" colSpan={3}>
                  Nama Pengguna
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Password
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Nomor Ponsel
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {state.loading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : dataPengguna
                  .filter((item) =>
                    item.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .slice(
                    state.page * rowsPerPage,
                    state.page * rowsPerPage + rowsPerPage
                  ).length > 0 ? (
                dataPengguna
                  .filter((item) =>
                    item.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .slice(
                    state.page * rowsPerPage,
                    state.page * rowsPerPage + rowsPerPage
                  )
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-0" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className="px-0" align="left" colSpan={3}>
                        {item.name}
                      </TableCell>
                      <TableCell
                        className="px-0"
                        align="center"
                        colSpan={2}
                        style={{ fontSize: 30 }}
                      >
                        ••••••••
                      </TableCell>
                      <TableCell className="px-0" align="center" colSpan={2}>
                        {item.phone}
                      </TableCell>
                      <TableCell>
                        <MenuActions
                          deleteActions={() => handleDelete(item.users_code)}
                          editPath={`/pengaturan/pengguna/edit/${item.users_code}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row" p={1} alignItems="center">
            <Box p={1}>
              <div className="flex">
                <p>Rows per page : </p>
                <select
                  labelid="simple-select"
                  value={rowsPerPage}
                  name="rowsPerPage"
                  onChange={handleSetRowsPerPage}
                  variant="standard"
                  className="border-none"
                  disabled={state.page > 0}
                >
                  {rowsPerPageOptions.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </Box>
          </Box>
          <Box>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[]}
              component="div"
              count={dataPenggunaPaginate.total || 0}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={""}
              page={dataPenggunaPaginate.current_page - 1 || 0}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Pengguna;
