import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
} from "@material-ui/core";

const CustomTable = ({ columns, data }) => {
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOption: [5, 10, 50, 100],
  });

  const setRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setState({
      ...state,
      page: 0,
      rowsPerPage: newRowsPerPage,
    });
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Card style={{ width: "100%" }}>
      <TableContainer>
        <Table style={{ minWidth: 700 }}>
          <TableHead className="t-header">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={
                    column.field === "aksi" || column.field === "no"
                      ? "center"
                      : window.innerWidth < 768
                      ? "left"
                      : "center"
                  }
                  className="px-0"
                  colSpan={
                    column.field === "no" ? 1 : column.field === "aksi" ? 2 : 3
                  }
                >
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="t-body">
            {data.length > 0 ? (
              data
                .slice(
                  state.page * state.rowsPerPage,
                  state.page * state.rowsPerPage + state.rowsPerPage
                )
                .map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        align={
                          column.field === "aksi" || column.field === "no"
                            ? "center"
                            : window.innerWidth < 768
                            ? "left"
                            : "center"
                        }
                        colSpan={
                          column.field === "no"
                            ? 1
                            : column.field === "aksi"
                            ? 2
                            : 3
                        }
                        className="px-0"
                      >
                        {column.render
                          ? column.render(rowData)
                          : rowData[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.reduce(
                    (acc, column) =>
                      acc +
                      (column.field === "no"
                        ? 1
                        : column.field === "aksi"
                        ? 2
                        : 3),
                    0
                  )}
                  align="center"
                >
                  Data kosong
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div style={{ minWidth: 700 }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="row" p={1} alignItems="center">
              <Box p={1}>
                <div className="flex">
                  <p>Rows per page : </p>
                  <select
                    labelid="simple-select"
                    value={state.rowsPerPage}
                    name="rowsPerPage"
                    onChange={setRowsPerPage}
                    variant="standard"
                    className="border-none"
                  >
                    {state.rowsPerPageOption.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </Box>
            </Box>
            <Box>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[]}
                component="div"
                count={data.length}
                rowsPerPage={state.rowsPerPage}
                labelRowsPerPage={""}
                page={state.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </Box>
          </Box>
        </div>
      </TableContainer>
    </Card>
  );
};

export default CustomTable;
