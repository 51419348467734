/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  Icon,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";
import shortid from "shortid";
import MenuActions from "../components/MenuActions";
import { connect, useDispatch } from "react-redux";
import GeneralButton from "app/components/GeneralButton";
import { Link } from "react-router-dom";
import { AddCircleOutline } from "@material-ui/icons";
import Swal from "sweetalert2";
import {
  getDataPartai,
  deletePartai,
} from "app/redux/actions/PengaturanActions";
const PartaiTable = ({ dataPartai = [] }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 50, 100],
  });

  const [search, setSearch] = useState("");

  const getData = () => {
    dispatch(getDataPartai());
  };

  useEffect(() => {
    getData();
  }, []);

  const getTableRowNumber = (index) => {
    return state.page * state.rowsPerPage + index + 1;
  };

  const setRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setState({
      ...state,
      page: 0,
      rowsPerPage: newRowsPerPage,
    });
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (code) => {
    deletePartai(code)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>',
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  useEffect(() => {
    if (dataPartai.length > 0) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }
  }, [dataPartai]);

  return (
    <>
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex align-items-center justify-content-between"
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            placeholder="Cari"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            name="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon color="disabled">search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} className="flex flex-end">
          <Link to="/pengaturan/partai_terakhir/tambah">
            <GeneralButton
              variant="contained"
              color="primary"
              text="Tambah Partai"
              icon={<AddCircleOutline fontSize="small" />}
            />
          </Link>
        </Grid>
      </Grid>

      <Grid item lg={12} md={12}>
        <TableContainer>
          <Table style={{ minWidth: 700 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align={window.innerWidth > 768 ? "center" : ""}>
                  Logo
                </TableCell>
                <TableCell align="center">Nama Partai</TableCell>
                <TableCell align="center">Jumlah Caleg</TableCell>
                <TableCell align="center">Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {state.loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              ) : dataPartai.length > 0 ? (
                dataPartai
                  .filter((item) =>
                    item.partai_nama
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((item, index) => (
                    <TableRow key={shortid.generate()}>
                      <TableCell className="px-0" align="center">
                        {getTableRowNumber(index)}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        <Box
                          style={{
                            justifyContent:
                              window.innerWidth > 768 ? "center" : "",
                            display: window.innerWidth > 768 ? "flex" : "block",
                          }}
                        >
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              objectPosition: "top",
                              objectFit: "cover",
                            }}
                            alt={item.partai_nama}
                            src={item.partai_images}
                          />
                        </Box>
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.partai_nama}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.partai_caleg}
                      </TableCell>
                      <TableCell align="center">
                        <MenuActions
                          editPath={`/pengaturan/partai_terakhir/edit/${item.partai_code}`}
                          onDelete={handleDelete}
                          item={item}
                          state={item}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div style={{ minWidth: 700 }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="row" p={1} alignItems="center">
                <Box p={1}>
                  <div className="flex">
                    <p>Rows per page : </p>
                    <select
                      labelid="simple-select"
                      value={state.rowsPerPage}
                      name="rowsPerPage"
                      onChange={setRowsPerPage}
                      variant="standard"
                      className="border-none"
                    >
                      {state.rowsPerPageOptions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </Box>
              </Box>
              <Box>
                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={[]}
                  component="div"
                  count={dataPartai.length}
                  rowsPerPage={state.rowsPerPage}
                  labelRowsPerPage={""}
                  page={state.page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          </div>
        </TableContainer>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    partaiTerakhir: state.pengaturan.partaiTerakhir,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataPartai: () => dispatch(getDataPartai()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartaiTable);
