import { MatxLoadable } from "matx";

const Logistik = MatxLoadable({
  loader: () => import("./Logistik"),
});

const AddLogistik = MatxLoadable({
  loader: () => import("./AddLogistik"),
});

const EditLogistik = MatxLoadable({
  loader: () => import("./EditLogistik")
});

const AddPemakaian = MatxLoadable({
  loader: () => import("./AddPemakaian"),
});

// const EditPemakaian = MatxLoadable({
//   loader: () => import("./EditPemakaian")
// });

const AddPesanan = MatxLoadable({
  loader: () => import("./AddPesanan"),
});

// const EditPesanan = MatxLoadable({
//   loader: () => import("./EditPesanan")
// });

const LogistikRoutes = [
  {
    path: "/logistik",
    exact: true,
    component: Logistik,
  },
  {
    path: "/logistik/tambah",
    exact: true,
    component: AddLogistik,
  },
  {
    path: "/logistik/edit/:logistik_code",
    exact: true,
    component: EditLogistik
  },
  {
    path: "/pemakaian/tambah",
    exact: true,
    component: AddPemakaian,
  },
  // {
  //   path: "/pemakaian/edit",
  //   exact: true,
  //   component: EditPemakaian
  // }
  {
    path: "/pesanan/tambah",
    exact: true,
    component: AddPesanan,
  },
  // {
  //   path: "/pesanan/edit",
  //   exact: true,
  //   component: EditPesanan
  // }
];

export default LogistikRoutes;
