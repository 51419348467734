import { API, setAuthToken } from "config/API.js";
import { GET_CITY } from "../constant.js";

export const getCity = (body) => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.post(`/city`, body)
      .then((res) => {
        dispatch({
          type: GET_CITY,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CITY,
          payload: [],
        });
      });
  };
};