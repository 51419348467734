import AddKanddidatLain from "./KandidatLain/AddKandidatLain";
import EditKandidatLain from "./KandidatLain/EditKandidatLain";
import Pengaturan from "./Pengaturan";
import AddPengguna from "./Pengguna/AddPengguna";

import AddLogistik from "./MasterData/logistik/AddLogistik";
import AddLogistikUnit from "./MasterData/logistik/AddLogistikUnit";
import EditLogistik from "./MasterData/logistik/EditLogistik";
import EditLogistikUnit from "./MasterData/logistik/EditLogistikUnit";

import AddArusKas from "./MasterData/arus_kas/AddArusKas";
import EditArusKas from "./MasterData/arus_kas/EditArusKas";

import EditPartaiSekarang from "./MasterData/partai_pemilu_sekarang/EditPartaiSekarang";
import AddPartaiSekarang from "./MasterData/partai_pemilu_sekarang/AddPartaiSekarang";
import AddPartaiTerakhir from "./MasterData/partai_pemilu_terakhir/AddPartaiTerakhir";
import EditPartaiTerakhir from "./MasterData/partai_pemilu_terakhir/EditPartaiTerakhir";

import AddCalonTetap from "./MasterData/kandidat/AddCalonTetap";
import EditCalonTetap from "./MasterData/kandidat/EditCalonTetap";
import AddDapil from "./MasterData/kandidat/AddDapil";
import EditDapil from "./MasterData/kandidat/EditDapil";

import AddQuickCount from "./MasterData/quick_count/AddQuickCount";
import EditQuickCount from "./MasterData/quick_count/EditQuickCount";

import AddProvinsi from "./MasterData/data_daerah/provinsi/AddProvinsi";
import EditProvinsi from "./MasterData/data_daerah/provinsi/EditProvinsi";
import AddKotaKab from "./MasterData/data_daerah/kota_kabupaten/AddKotaKab";
import EditKotaKab from "./MasterData/data_daerah/kota_kabupaten/EditKotaKab";
import EditKecamatan from "./MasterData/data_daerah/kecamatan/EditKecamatan";
import AddKecamatan from "./MasterData/data_daerah/kecamatan/AddKecamatan";
import AddKelurahan from "./MasterData/data_daerah/kelurahan/AddKecamatan";
import EditKelurahan from "./MasterData/data_daerah/kelurahan/EditKecamatan";
import EditPengguna from "./Pengguna/EditPengguna";

const PengaturanRoutes = [
  {
    path: "/pengaturan",
    component: Pengaturan,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/pengguna/tambah",
    component: AddPengguna,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/pengguna/edit/:id",
    component: EditPengguna,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/kandidat_lain/tambah",
    component: AddKanddidatLain,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/kandidat_lain/edit/:id",
    component: EditKandidatLain,
    // auth: authRoles.all,
    exact: true,
  },
  // ROUTE MASTER DATA
  // LOGISTIK
  {
    path: "/pengaturan/data_logistik/tambah",
    component: AddLogistik,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_logistik/edit",
    component: EditLogistik,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_logistik/tambah_unit",
    component: AddLogistikUnit,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_logistik/edit_unit",
    component: EditLogistikUnit,
    // auth: authRoles.all,
    exact: true,
  },
  // ARUS KAS
  {
    path: "/pengaturan/arus_kas/tambah",
    component: AddArusKas,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/arus_kas/edit",
    component: EditArusKas,
    // auth: authRoles.all,
    exact: true,
  },
  // PARTAI PESEERTA PEMILU
  {
    path: "/pengaturan/partai_sekarang/tambah",
    component: AddPartaiSekarang,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/partai_sekarang/edit/:partai_code",
    component: EditPartaiSekarang,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/partai_terakhir/tambah",
    component: AddPartaiTerakhir,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/partai_terakhir/edit/:partai_code",
    component: EditPartaiTerakhir,
    // auth: authRoles.all,
    exact: true,
  },
  // Master Data Kandidat (Calon Tetap & Dapil)
  {
    path: "/pengaturan/calon_tetap/tambah",
    component: AddCalonTetap,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/calon_tetap/edit",
    component: EditCalonTetap,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/dapil/tambah",
    component: AddDapil,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/dapil/edit",
    component: EditDapil,
    // auth: authRoles.all,
    exact: true,
  },
  // QUICK COUNT
  {
    path: "/pengaturan/quick_count/tambah",
    component: AddQuickCount,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/quick_count/edit",
    component: EditQuickCount,
    // auth: authRoles.all,
    exact: true,
  },
  // DATA DAERAH
  // Data Provinsi
  {
    path: "/pengaturan/data_provinsi/tambah",
    component: AddProvinsi,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_provinsi/edit",
    component: EditProvinsi,
    // auth: authRoles.all,
    exact: true,
  },
  // Data Kota Kabupaten
  {
    path: "/pengaturan/data_kota_kabupaten/tambah",
    component: AddKotaKab,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_kota_kabupaten/edit",
    component: EditKotaKab,
    // auth: authRoles.all,
    exact: true,
  },
  // Data Kecamatan
  {
    path: "/pengaturan/data_kecamatan/tambah",
    component: AddKecamatan,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_kecamatan/edit",
    component: EditKecamatan,
    // auth: authRoles.all,
    exact: true,
  },
  // Data Kecamatan
  {
    path: "/pengaturan/data_kelurahan/tambah",
    component: AddKelurahan,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/pengaturan/data_kelurahan/edit",
    component: EditKelurahan,
    // auth: authRoles.all,
    exact: true,
  },
];

export default PengaturanRoutes;
