import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  Box,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addLogistik } from "app/redux/actions/LogistikActions";
import { useHistory } from "react-router-dom";
import InputFileImg from "app/components/Input/InputFileImg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { CalendarIcon } from "app/components/Icons";
import { getDataRelawan } from "app/redux/actions/RelawanActions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AddLaporan = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    laporan_nama: "",
    status: "",
    logistik_stok: "",
    logistik_unit_code: "",
    logistik_harga: "",
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTanggal = (e) => {
    setState((prevState) => ({
      ...prevState,
      pemakaian_tanggal: e,
    }));
  };

  const handleSubmit = () => {
    if (
      state.laporan_nama == "" ||
      state.status == "" ||
      state.logistik_stok == "" ||
      state.logistik_unit_code == "" ||
      state.logistik_harga == ""
    ) {
      Swal.fire("Gagal !", "Harap isi semua inputan !", "warning");
      return;
    }

    addLogistik(state)
      .then((res) => {
        Swal.fire("Berhasil", "", "success");
        history.push("/logistik");
      })
      .catch((err) => {
        Swal.fire("Gagal !", "Harap coba lagi !", "error");
      });
  };

  const getData = () => {
    dispatch(getDataRelawan());
  };

  useEffect(() => {
    getData();
  }, []);

  const { dataRelawan } = useSelector((state) => state.relawan);

  return (
    <div className="p-40">
      <div
        className="mb-sm-30"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="font-size-32 fw-700 fw-bold m-0">
          Tambah Stok Logistik
        </h1>
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            className="p-10 bg-green-50 px-3"
            onClick={handleSubmit}
            style={{
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <span className="">Simpan Data</span>
          </Button>
        </div>
      </div>

      <Card style={{ padding: "20px" }}>
        <Grid container className="mt-2 p-4" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-600 fs-24">Detail Laporan</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputFileImg noPadding={true} />
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}></Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="provinsi">
              <h5 className="font-size-14" style={{ marginBottom: "-5px" }}>
                Tanggal
              </h5>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                style={{ marginTop: "50px" }}
              >
                <DatePicker
                  label=""
                  className={`w-100 pb-0`}
                  size="small"
                  required={true}
                  InputProps={{
                    style: {
                      borderRadius: 5,
                      padding: 0,
                    },
                  }}
                  placeholder="Rp 2.000.000"
                  variant="outlined"
                  onChange={handleChangeTanggal}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  views={["year", "month", "day"]}
                  value={state.pemakaian_tanggal}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="name">
              <h5 className="font-size-14">Nama Laporan</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.laporan_nama}
              name="laporan_nama"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama Atribut"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
          <InputLabel htmlFor="deskripsi">
              <h5 className="font-size-14">Deskripsi</h5>
            </InputLabel>
            <textarea
              name="deskripsi"
              placeholder="Tulis disini"
              rows="5"
              className="w-full"
              id="deskripsi"
              // onChange={this.handleChange}
            ></textarea>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ padding: "20px" }} className="mt-2">
        <Grid container className="mt-2 p-4" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">Info Lainnya</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="relawan">
              <h5 className="font-size-14">Relawan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: "#FBFBFB",
                },
              }}
              label="Una"
              size="small"
              labelid="relawan"
              value={state.relawan}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="relawan"
              displayEmpty
            >
              {dataRelawan.map((item) => (
                <MenuItem value={item.relawan_code}>
                  {item.relawan_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="status">
              <h5 className="font-size-14">Status</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: "#FBFBFB",
                },
              }}
              label="Una"
              size="small"
              labelid="status"
              value={state.status}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="status"
              displayEmpty
            >
              <MenuItem value="Selesai">Selesai</MenuItem>
              <MenuItem value="Proses">Proses</MenuItem>
              <MenuItem value="Baru">Baru</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default AddLaporan;
