import React from "react";
import ReactEcharts from "echarts-for-react";
import { withStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const BarChart = ({ color = [], dataY = [], dataX = [] }) => {
  const option = {
    grid: {
      top: "0%",
      bottom: "10%",
      left: "1%",
      right: "5%",
      containLabel: true,
    },
    legend: {
      show: true,
    },
    yAxis: {
      type: "category",
      data: dataY,
      show: true,
      position: "auto",
      axisLine: {
        show: false,
      },
      z: 3,
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "#FFF",
        inside: true,
        show: true,
        position: "right",
      },
      nameLocation: "start",
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: color,
          opacity: 0.15,
        },
      },
      axisLabel: {
        color: color,
        fontSize: 13,
        fontFamily: "Inter",
      },
    },
    series: [
      {
        data: dataX,
        type: "bar",
      },
    ],
    tooltip: {
      show: true,
      trigger: "item",
      formatter: "{b}: {c}",
    },
  };

  return (
    <>
      <ReactEcharts
        style={{ height: 100 * dataY.length }}
        option={{
          ...option,
          color: [...color],
        }}
      />
      <div className="d-flex align-items-center justify-content-center">
        {[...dataY].map((item) => (
          <>
            <FiberManualRecord htmlColor={item.itemStyle.color} />
            <h5 className="fs-14 me-2 mb-0">{item.name}</h5>
          </>
        ))}
      </div>
    </>
  );
};

export default withStyles({}, { withTheme: true })(BarChart);
