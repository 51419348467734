/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CardTotalRelawan from "app/components/card/CardTotalRelawan";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrafikStatistikPemilihDaily,
  getGrafikStatistikPemilihMonthly,
  getStatisticPemilih,
} from "app/redux/actions/StatisticActions";
import ChartStatisticPemilih from "./components/ChartStatisticPemilih";
import { Users } from "@phosphor-icons/react";

const StatistikPemilih = ({
  selectedFilter,
  setSelectedFilter,
  setSelectedYear,
  filterDataGrafik,
}) => {
  const dispatch = useDispatch();

  const tabTexts = {
    title: "Warga Binaan",
    card_title_1: "Total Calon Pemilih",
    card_title_2: "Calon Pemilih Pria",
    card_title_3: "Calon Pemilih Wanita",
    card_title_4: "Total Calon Pemilih Pasti Memilih",
    card_title_5: "Total Calon Pemilih Ragu Ragu",
    card_title_6: "Total Calon Pemilih Tidak Memilih",
  };

  const {
    dataStatisticPemilih,
    dataGrafikPemilihDaily,
    dataGrafikPemilihMonthly,
  } = useSelector((state) => state.statistic);

  const getData = () => {
    dispatch(getStatisticPemilih());
    dispatch(getGrafikStatistikPemilihDaily());
    dispatch(getGrafikStatistikPemilihMonthly());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mb-3">
        <Grid container spacing={2} marginBottom="15px">
          {/* GRID 1 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_1}
              totalJumlah={dataStatisticPemilih.totalPemilih}
              icon={Users}
            />
          </Grid>
          {/* GRID 2 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_2}
              totalJumlah={dataStatisticPemilih.totalPemilihPria}
              icon={Users}
            />
          </Grid>
          {/* GRID 3 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_3}
              totalJumlah={dataStatisticPemilih.totalPemilihWanita}
              icon={Users}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* GRID 4 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_4}
              totalJumlah={dataStatisticPemilih.totalPemilihMemilih}
              className="text-green-50"
              icon={Users}
            />
          </Grid>

          {/* GRID 5 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_5}
              totalJumlah={dataStatisticPemilih.totalPemilihRagu}
              className="text-yellow"
              icon={Users}
            />
          </Grid>

          {/* GRID 6 */}
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_6}
              totalJumlah={dataStatisticPemilih.totalPemilihTidakMemilih}
              className="text-red-50"
              icon={Users}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <ChartStatisticPemilih
            dataGrafikPemilihDaily={dataGrafikPemilihDaily}
            dataGrafikPemilihMonthly={dataGrafikPemilihMonthly}
            selectedFilter={selectedFilter}
            onSelectFilter={setSelectedFilter}
            onSelectYear={setSelectedYear}
            getDataGrafik={filterDataGrafik}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StatistikPemilih;
