import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { Eye, Transaction } from "app/components/icon";

export default function MenuActions({
  item,
  editPath,
  deleteAction,
  detailPath,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteAction();
    handleClose();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        // id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={detailPath}>
          <MenuItem
            className=""
            onClick={handleDelete}
            style={{
              width: "163px",
              height: "37px",
              padding: "8px 12px 8px 12px",
              gap: "10px",
            }}
          >
            <Eye />
            <span className="pl-3" style={{ color: "#1C1C1C" }}>
              Lihat Detail
            </span>
          </MenuItem>
        </Link>
        <MenuItem
          className=""
          onClick={handleDelete}
          style={{
            width: "163px",
            height: "37px",
            padding: "8px 12px 8px 12px",
            gap: "10px",
          }}
        >
          <Transaction />
          <span className="pl-3" style={{ color: "#1C1C1C" }}>
            Edit Status
          </span>
        </MenuItem>
        <Link
          to={{
            pathname: editPath,
            state: { itemData: item },
          }}
        >
          <MenuItem
            onClick={handleClose}
            style={{
              width: "163px",
              height: "37px",
              padding: "8px 12px 8px 12px",
              gap: "10px",
            }}
          >
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none "
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 6.5003L17.5 10.5003M16 18.0003H20M8 20.0003L18.5 9.5003C18.7626 9.23766 18.971 8.92585 19.1131 8.58269C19.2553 8.23953 19.3284 7.87174 19.3284 7.5003C19.3284 7.12887 19.2553 6.76107 19.1131 6.41791C18.971 6.07475 18.7626 5.76295 18.5 5.5003C18.2374 5.23766 17.9256 5.02932 17.5824 4.88718C17.2392 4.74503 16.8714 4.67187 16.5 4.67188C16.1286 4.67188 15.7608 4.74503 15.4176 4.88718C15.0744 5.02932 14.7626 5.23766 14.5 5.5003L4 16.0003V20.0003H8Z"
                  stroke="#71BE42"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="pl-3" style={{ color: "#71BE42" }}>
              Edit Data
            </span>
          </MenuItem>
        </Link>

        <MenuItem
          className=""
          onClick={handleDelete}
          style={{
            width: "163px",
            height: "37px",
            padding: "8px 12px 8px 12px",
            gap: "10px",
          }}
        >
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#E34242"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.75V3H21.25C21.4489 3 21.6397 3.07902 21.7803 3.21967C21.921 3.36032 22 3.55109 22 3.75C22 3.94891 21.921 4.13968 21.7803 4.28033C21.6397 4.42098 21.4489 4.5 21.25 4.5H2.75C2.55109 4.5 2.36032 4.42098 2.21967 4.28033C2.07902 4.13968 2 3.94891 2 3.75C2 3.55109 2.07902 3.36032 2.21967 3.21967C2.36032 3.07902 2.55109 3 2.75 3H8V1.75C8 0.784 8.784 0 9.75 0H14.25C15.216 0 16 0.784 16 1.75ZM9.5 1.75V3H14.5V1.75C14.5 1.6837 14.4737 1.62011 14.4268 1.57322C14.3799 1.52634 14.3163 1.5 14.25 1.5H9.75C9.6837 1.5 9.62011 1.52634 9.57322 1.57322C9.52634 1.62011 9.5 1.6837 9.5 1.75ZM4.997 6.178C4.98845 6.07926 4.96041 5.9832 4.91452 5.89536C4.86862 5.80752 4.80576 5.72965 4.72959 5.66625C4.65341 5.60284 4.56542 5.55517 4.47071 5.52598C4.376 5.49678 4.27644 5.48665 4.17779 5.49617C4.07914 5.50568 3.98336 5.53465 3.89597 5.58141C3.80858 5.62817 3.73133 5.69178 3.66868 5.76857C3.60602 5.84536 3.55921 5.93381 3.53095 6.0288C3.50268 6.12379 3.49352 6.22345 3.504 6.322L4.916 20.92C4.95823 21.3527 5.16001 21.7542 5.48203 22.0462C5.80405 22.3383 6.22325 22.5001 6.658 22.5H17.342C17.7769 22.5 18.1962 22.3382 18.5183 22.0459C18.8403 21.7536 19.042 21.3519 19.084 20.919L20.497 6.322C20.5161 6.12388 20.4557 5.9263 20.3291 5.7727C20.2025 5.61911 20.0201 5.5221 19.822 5.503C19.6239 5.4839 19.4263 5.54429 19.2727 5.67088C19.1191 5.79747 19.0221 5.97988 19.003 6.178L17.591 20.774C17.585 20.8359 17.5562 20.8933 17.5102 20.9351C17.4641 20.9769 17.4042 21 17.342 21H6.658C6.59582 21 6.53587 20.9769 6.48983 20.9351C6.44379 20.8933 6.41497 20.8359 6.409 20.774L4.997 6.178Z"
                fill="#E34242"
              />
              <path
                d="M9.20536 7.50081C9.30372 7.495 9.40227 7.50862 9.49537 7.54091C9.58847 7.5732 9.6743 7.62352 9.74795 7.68899C9.82159 7.75446 9.88161 7.8338 9.92458 7.92248C9.96754 8.01116 9.99261 8.10744 9.99835 8.20582L10.4984 16.7058C10.51 16.9046 10.4423 17.0999 10.3099 17.2487C10.1776 17.3975 9.99163 17.4876 9.79285 17.4993C9.59407 17.511 9.3988 17.4432 9.24999 17.3109C9.10118 17.1786 9.01102 16.9926 8.99935 16.7938L8.49935 8.29381C8.49354 8.19545 8.50716 8.0969 8.53945 8.0038C8.57174 7.91069 8.62206 7.82487 8.68753 7.75122C8.753 7.67758 8.83235 7.61756 8.92102 7.57459C9.0097 7.53163 9.10598 7.50656 9.20435 7.50081H9.20536ZM15.4984 8.29381C15.51 8.09503 15.4423 7.89976 15.3099 7.75095C15.1776 7.60214 14.9916 7.51198 14.7929 7.50032C14.5941 7.48865 14.3988 7.55642 14.25 7.68873C14.1012 7.82103 14.011 8.00704 13.9994 8.20582L13.4994 16.7058C13.4877 16.9045 13.5554 17.0996 13.6876 17.2483C13.8198 17.397 14.0057 17.4871 14.2044 17.4988C14.403 17.5105 14.5981 17.4428 14.7469 17.3106C14.8956 17.1783 14.9857 16.9925 14.9974 16.7938L15.4984 8.29381Z"
                fill="#E34242"
              />
            </svg>
          </span>
          <span className="pl-3" style={{ color: "#E34242" }}>
            Hapus Data
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}
