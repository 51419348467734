/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MenuActions from "../components/MenuActions";
import CustomLayout from "../components/CustomLayout";
import {
  getDataDapil,
  deleteDapil,
  getDataCalonTetapSebagai,
  deleteCalonTetapSebagai,
} from "app/redux/actions/PengaturanActions";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

const MasterKandidat = () => {
  const dispatch = useDispatch();
  const { dataDapil, dataCalonTetap } = useSelector(
    (state) => state.pengaturan
  );

  const deleteDataDapil = (code) => {
    const payload = {
      dapil_code: code,
    };
    deleteDapil(payload)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>', icon: 'success',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>', icon: 'error',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const deleteDataCalonTetapSebagai = (code) => {
    const payload = {
      tingkat_calon_tetap_code: code,
    };
    deleteCalonTetapSebagai(payload)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil dihapus!</div>', icon: 'success',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal dihapus!</div>', icon: 'error',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const calonTetapColumns = [
    { header: "No", field: "no" },
    { header: "Nama Calon Tetap Sebagai", field: "tingkat_calon_tetap_nama" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath="/pengaturan/calon_tetap/edit"
          data={rowData}
          item={rowData}
          state={rowData}
          onDelete={(item) =>
            deleteDataCalonTetapSebagai(item.tingkat_calon_tetap_code)
          }
        />
      ),
    },
  ];

  const dapilColumns = [
    { header: "No", field: "no" },
    { header: "Nama Dapil", field: "dapil_nama" },
    { header: "Provinsi", field: "province_name" },
    {
      header: "Aksi",
      render: (rowData) => (
        <MenuActions
          editPath="/pengaturan/dapil/edit"
          data={rowData}
          item={rowData}
          state={rowData}
          onDelete={(item) => deleteDataDapil(item.dapil_code)}
        />
      ),
    },
  ];

  const [searchCalonTetapSebagai, setSearchCalonTetapSebagai] = useState("");
  const [searchDapil, setSearchDapil] = useState("");

  const getData = () => {
    dispatch(getDataDapil());
    dispatch(getDataCalonTetapSebagai());
  };

  const dataDapilWithNo = dataDapil
    .filter((item) =>
      item.dapil_nama.toLowerCase().includes(searchDapil.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  const dataCalonTetapWithNo = dataCalonTetap
    .filter((item) =>
      item.tingkat_calon_tetap_nama
        .toLowerCase()
        .includes(searchCalonTetapSebagai.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className="w-100 overflow-auto p-24 mb-3" elevation={6}>
        <CustomLayout
          title="Data Kandidat dan Kandidat Lainnya"
          subtitle="Calon Tetap Sebagai"
          columns={calonTetapColumns}
          data={dataCalonTetapWithNo}
          button="Tambah Calon Tetap"
          addPath="/pengaturan/calon_tetap/tambah"
          search={searchCalonTetapSebagai}
          setSearch={setSearchCalonTetapSebagai}
        />
        <CustomLayout
          subtitle="Dapil"
          columns={dapilColumns}
          data={dataDapilWithNo}
          button="Tambah Dapil"
          addPath="/pengaturan/dapil/tambah"
          search={searchDapil}
          setSearch={setSearchDapil}
        />
      </Card>
    </>
  );
};

export default MasterKandidat;
