import React from "react";
import { Box, Hidden, Grid } from "@material-ui/core";
import { TabButton } from "app/components";

const NavTabs = ({ initialTab, history, tabs }) => {
  return (
    <Box className="flex flex-wrap gap-2 p-16">
      <Box className="flex gap-2 xs-hidden">
        {tabs.map((tab) => (
          <Box key={tab.key} className="flex flex-wrap flex-grow-1 xs-hidden">
            <TabButton
              selected={initialTab === tab.key}
              onClick={() => history.push(tab.path)}
              title={tab.title}
              otherClass="flex-shrink-0"
              icon={tab.icon}
            />
          </Box>
        ))}
      </Box>

      <Hidden smUp>
        <Grid container>
          {tabs.map((tab) => (
            <Grid key={tab.key} item xs={12} className="mb-2">
              <Box className="flex flex-wrap">
                <TabButton
                  selected={initialTab === tab.key}
                  onClick={() => history.push(tab.path)}
                  title={tab.title}
                  otherClass="flex-grow-1"
                  icon={tab.icon}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </Box>
  );
};

export default NavTabs;
