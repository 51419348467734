import React from "react";

const Eye = ({ color = "#1C1C1C" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M12.9833 10.4999C12.9833 12.1499 11.6499 13.4833 9.99993 13.4833C8.34993 13.4833 7.0166 12.1499 7.0166 10.4999C7.0166 8.84993 8.34993 7.5166 9.99993 7.5166C11.6499 7.5166 12.9833 8.84993 12.9833 10.4999Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99987 17.3913C12.9415 17.3913 15.6832 15.6579 17.5915 12.6579C18.3415 11.4829 18.3415 9.50794 17.5915 8.33294C15.6832 5.33294 12.9415 3.59961 9.99987 3.59961C7.0582 3.59961 4.31654 5.33294 2.4082 8.33294C1.6582 9.50794 1.6582 11.4829 2.4082 12.6579C4.31654 15.6579 7.0582 17.3913 9.99987 17.3913Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Eye;
