import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import CardTimeLeft from "app/components/card/CardTimeLeft";

const CardHitungMundur = ({ timeLeft, voteDate }) => {
    return (
        <Grid item sm={12} xs={12} md={8} lg={8}>
            <Card className="flex flex-column p-20 h-100">
                <CardContent className="p-24">
                    <Grid
                        container
                        spacing={2}
                        className="flex justify-content-between align-item-center"
                    >
                        <Grid item>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                style={{ fontWeight: "bold" }}
                            >
                                Hitung mundur waktu vote
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "#FFEEEE",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#FF5656",
                                }}
                            >
                                <span className="fs-6 fw-500 p-2">
                                    {voteDate + " WIB"}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container className="mt-4" spacing={2}>
                            <CardTimeLeft
                                countdown={timeLeft.monthsLeft}
                                timeDesc="Bulan"
                            />
                            <CardTimeLeft
                                countdown={timeLeft.daysLeft}
                                timeDesc="Hari"
                            />
                            <CardTimeLeft
                                countdown={timeLeft.hoursLeft}
                                timeDesc="Jam"
                            />
                            <CardTimeLeft
                                countdown={timeLeft.minutesLeft}
                                timeDesc="Menit"
                            />
                            <CardTimeLeft
                                countdown={timeLeft.secondsLeft}
                                timeDesc="Detik"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CardHitungMundur;
