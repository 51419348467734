export const DASHBOARD_DATA = 'DASHBOARD_DATA'
export const GET_WARGA_BINAAN = 'GET_WARGA_BINAAN'
export const GET_RELAWAN = 'GET_RELAWAN'
export const GET_DETAIL_RELAWAN = 'GET_DETAIL_RELAWAN'
export const GET_TEAM_MANAGEMENT = 'GET_TEAM_MANAGEMENT'
export const GET_TEAM_MANAGEMENT_LIST = 'GET_TEAM_MANAGEMENT_LIST'
export const GET_DETAIL_TEAM_MANAGEMENT = 'GET_DETAIL_TEAM_MANAGEMENT'
export const GET_ARUS_KAS = 'GET_ARUS_KAS'
export const GET_LOGISTIK = 'GET_LOGISTIK'
export const GET_LOGISTIK_SATUAN_UNIT = 'GET_LOGISTIK_SATUAN_UNIT'
export const GET_PEMAKAIAN = 'GET_PEMAKAIAN'
export const GET_SURVEY = 'GET_SURVEY'
export const GET_PENGGUNA = 'GET_PENGGUNA'
export const GET_KANDIDAT_LAIN = 'GET_KANDIDAT_LAIN'
export const GET_PEMILU_TERAKHIR = 'GET_PEMILU_TERAKHIR'
export const GET_PROVINCE = 'GET_PROVINCE'
export const GET_CITY = 'GET_CITY'
export const GET_DISTRICT = 'GET_DISTRICT'
export const GET_UNIT = 'GET_UNIT'
export const GET_CATEGORY_LOGISTIK = 'GET_CATEGORY_LOGISTIK'
export const GET_VILLAGES = 'GET_VILLAGES'
export const GET_STATISTIC_PEMILIH = 'GET_STATISTIC_PEMILIH'
export const GET_STATISTIC_RELAWAN = 'GET_STATISTIC_RELAWAN'
export const GET_STATISTIC_LOGISTIC = 'GET_STATISTIC_LOGISTIC'
export const GET_PARTAI = 'GET_PARTAI'
export const GET_KANDIDAT_MAIN = 'GET_KANDIDAT_MAIN'
export const GET_DAPIL = 'GET_DAPIL'
export const GET_CALON_TETAP_SEBAGAI = 'GET_CALON_TETAP_SEBAGAI'
export const GET_DETAIL_KANDIDAT = 'GET_DETAIL_KANDIDAT'
export const GET_WILAYAH = 'GET_WILAYAH'
export const GET_DETAIL_WARGA_BINAAN = 'GET_DETAIL_WARGA_BINAAN'
export const GET_DETAIL_PARTAI = 'GET_DETAIL_PARTAI'
export const GET_DETAIL_CALON_TETAP = 'GET_DETAIL_CALON_TETAP'
export const GET_CATEGORY_ARUS_KAS = 'GET_CATEGORY_ARUS_KAS'
export const GET_TPS = 'GET_TPS'
export const GET_PESANAN = 'GET_PESANAN'
export const GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN =
  'GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN'
export const GET_DETAIL_PENGGUNA = 'GET_DETAIL_PENGGUNA'
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const GET_GRAFIK_PEMILIH_DAILY = 'GET_GRAFIK_PEMILIH_DAILY'
export const GET_GRAFIK_PEMILIH_MONTHLY = 'GET_GRAFIK_PEMILIH_MONTHLY'
export const GET_GRAFIK_RELAWAN_DAILY = 'GET_GRAFIK_RELAWAN_DAILY'
export const GET_GRAFIK_RELAWAN_MONTHLY = 'GET_GRAFIK_RELAWAN_MONTHLY'
export const GET_RELAWAN_STATISTIC = 'GET_RELAWAN_STATISTIC'
export const GET_PROVINSI_PT = 'GET_PROVINSI_PT'
export const GET_KABUPATEN_PT = 'GET_KABUPATEN_PT'
export const GET_KECAMATAN_PT = 'GET_KECAMATAN_PT'
export const GET_KELURAHAN_PT = 'GET_KELURAHAN_PT'
export const GET_DETAIL_TPS = 'GET_DETAIL_TPS'
export const GET_DASHBOARD_GRAFIK_DAILY = 'GET_DASHBOARD_GRAFIK_DAILY'
export const GET_DASHBOARD_GRAFIK_MONTHLY = 'GET_DASHBOARD_GRAFIK_MONTHLY'
export const GET_GRAFIK_REKAP_SUARA = 'GET_GRAFIK_REKAP_SUARA'
export const GET_RANGKUMAN_PARTAI = 'GET_RANGKUMAN_PARTAI'
export const GET_RANGKUMAN_CALEG = 'GET_RANGKUMAN_CALEG'
export const GET_GRAFIK_RANGKUMAN_CALEG = 'GET_GRAFIK_RANGKUMAN_CALEG'
export const GET_RANGKUMAN_SUARA_PARTAI = 'GET_RANGKUMAN_SUARA_PARTAI'
export const GET_GRAFIK_RANGKUMAN_SUARA_PARTAI = 'GET_GRAFIK_RANGKUMAN_SUARA_PARTAI'
export const GET_GRAFIK_RANGKUMAN_PARTAI = 'GET_GRAFIK_RANGKUMAN_PARTAI'
export const GET_DETAIL_RANGKUMAN_PARTAI = 'GET_DETAIL_RANGKUMAN_PARTAI'
export const GET_LIST_CALEG_PERPARTAI = 'GET_LIST_CALEG_PERPARTAI'
export const GET_SUARA_CALEG_PERPARTAI = 'GET_SUARA_CALEG_PERPARTAI'
export const GET_SEBARAN_SUARA = 'GET_SEBARAN_SUARA'
export const GET_HITUNG_CEPAT = 'GET_HITUNG_CEPAT'
export const GET_DETAIL_HITUNG_CEPAT = 'GET_DETAIL_HITUNG_CEPAT'
export const GET_HITUNG_CEPAT_GRAFIK = 'GET_HITUNG_CEPAT_GRAFIK'