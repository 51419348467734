/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import InputFileImg from "app/components/Input/InputFileImg";
import GeneralButton from "app/components/GeneralButton";
import { CheckCircleOutline } from "@material-ui/icons";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getDataCalonTetapSebagai,
  getDataDapil,
  getDataPartai,
  getKandidatMain,
  updateKandidat,
} from "app/redux/actions/PengaturanActions";

const Kandidat = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    kandidat_images: "",
    kandidat_images_preview: "",
    kandidat_nama: "",
    kandidat_nomor_urut: "",
    kandidat_partai: "",
    kandidat_dapil: "",
    kandidat_tingkat_code: "",
    kandidat_hitung_mundur: new Date(),
    kandidat_code: "",
    updated: false,
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    updateKandidat(state)
      .then((res) => {
        // console.log(res)
        Swal.fire({
          title:
            '<div class="custom-swal-title">Data berhasil diperbarui</div>',
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal diupdate</div>',
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      kandidat_images: file,
      kandidat_images_preview: path,
    }));
  };

  const handleDateChange = (date) => {
    setState((prev) => ({
      ...prev,
      kandidat_hitung_mundur: date,
    }));
  };

  useEffect(() => {
    dispatch(getDataPartai());
    dispatch(getKandidatMain());
    dispatch(getDataDapil());
    dispatch(getDataCalonTetapSebagai());
  }, [dispatch]);

  const { partaiSekarang, kandidat_main, dataDapil, dataCalonTetap } =
    useSelector((state) => state.pengaturan);

  useEffect(() => {
    if (kandidat_main.hasOwnProperty("kandidat_code") && !state.updated) {
      setState({
        kandidat_code: kandidat_main?.kandidat_code,
        // kandidat_images: kandidat_main?.kandidat_images,
        kandidat_images_preview: kandidat_main?.kandidat_images,
        kandidat_nama: kandidat_main?.kandidat_nama,
        kandidat_nomor_urut: kandidat_main?.kandidat_nomor_urut,
        kandidat_partai: kandidat_main?.kandidat_partai,
        kandidat_dapil: kandidat_main?.kandidat_dapil,
        kandidat_tingkat_code: kandidat_main?.kandidat_tingkat_code,
        kandidat_hitung_mundur: kandidat_main?.kandidat_hitung_mundur,
        updated: true,
      });
    }
  }, [kandidat_main]);

  return (
    <Box className="analytics">
      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2" spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className="flex justify-content-between align-items-center flex-wrap"
          >
            <h3 className="h2-subtitle">Detail Kandidat</h3>
            <GeneralButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              icon={<CheckCircleOutline />}
              text="Simpan"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="image">
              <h5 className="font-size-14">Foto Kandidat</h5>
              <InputFileImg
                noPadding={true}
                name="kandidat_images"
                uploadFoto={handleChangeFoto}
                preview={state.kandidat_images_preview}
                acceptFile="image"
              />
            </InputLabel>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            {/*  */}
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_nama">
              <h5 className="font-size-14">Nama Kandidat</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.kandidat_nama}
              name="kandidat_nama"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama Kandidat"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_nomor_urut">
              <h5 className="font-size-14">Nomor Kandidat</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kandidat_nomor_urut"
              value={state.kandidat_nomor_urut}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="kandidat_nomor_urut"
              displayEmpty
            >
              {[...Array(100).keys()].map((item) => (
                <MenuItem key={item + 1} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_partai">
              <h5 className="font-size-14">Partai</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kandidat_partai"
              value={state.kandidat_partai}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="kandidat_partai"
              displayEmpty
            >
              {partaiSekarang?.map((item) => (
                <MenuItem key={item.partai_code} value={item.partai_code}>
                  {item.partai_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_hitung_mundur">
              <h5 className="font-size-14">Hitung Mundur Pemilu</h5>
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-full">
              <DateTimePicker
                margin="none"
                id="mui-pickers-date"
                inputVariant="outlined"
                hiddenLabel
                autoOk={true}
                className="w-full"
                name="kandidat_hitung_mundur"
                value={state.kandidat_hitung_mundur}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Card>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-600 fs-24">Jenis Pemilihan</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_dapil">
              <h5 className="font-size-14">Dapil</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kandidat_dapil"
              value={state.kandidat_dapil}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="kandidat_dapil"
              displayEmpty
            >
              {dataDapil.map((item) => (
                <MenuItem key={item.dapil_code} value={item.dapil_code}>
                  {item.dapil_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="kandidat_tingkat_code">
              <h5 className="font-size-14">Calon tetap sebagai:</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kandidat_tingkat_code"
              value={state.kandidat_tingkat_code}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="kandidat_tingkat_code"
              displayEmpty
            >
              {dataCalonTetap.map((item) => (
                <MenuItem
                  key={item.tingkat_calon_tetap_code}
                  value={item.tingkat_calon_tetap_code}
                >
                  {item.tingkat_calon_tetap_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default Kandidat;
