import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import RelawanRoutes from "./views/relawan/RelawanRoutes";
import WargaBinaanRoutes from "./views/warga_binaan/WargaBinaanRoutes";
import ArusKasRoutes from "./views/arus_kas/ArusKasRoutes";
import StatistikRoutes from "./views/statistik/StatistikRoutes";
import LogistikRoutes from "./views/logistik/LogistikRoutes";
import SurveyRoutes from "./views/survey/SurveyRoutes";
import LaporanRoutes from "./views/laporan/LaporanRoutes";
import PengaturanRoutes from "./views/pengaturan/PengaturanRoutes";
import PemiluTerakhirRoutes from "./views/pemilu_terakhir/PemiluTerakhirRoutes";
import ProfileRoutes from "./views/profile/ProfileRoutes";
import HitungCepatRoutes from "./views/hitung_cepat/HitungCepatRoutes";
// import SebaranSuaraRoutes from "./views/sebaran_suara/SebaranSuaraRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...RelawanRoutes,
  ...WargaBinaanRoutes,
  ...StatistikRoutes,
  ...ArusKasRoutes,
  ...LogistikRoutes,
  ...SurveyRoutes,
  ...LaporanRoutes,
  ...PengaturanRoutes,
  ...PemiluTerakhirRoutes,
  ...ProfileRoutes,
  ...HitungCepatRoutes,
  // ...SebaranSuaraRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
