import AddLaporan from "./AddLaporan";
import Laporan from "./Laporan";

const LaporanRoutes = [
  {
    path: "/laporan",
    component: Laporan,
    // auth: authRoles.all,
    exact: true,
  },
  {
    path: "/laporan/tambah",
    component: AddLaporan,
    // auth: authRoles.all,
    exact: true,
  },
];

export default LaporanRoutes;
