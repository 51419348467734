/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Box,
  Hidden
} from '@material-ui/core'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import {
  getDataArusKasDetail,
  getKategoriArusKas
} from 'app/redux/actions/ArusKasActions'
import InputFileImg from 'app/components/Input/InputFileImg'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CalendarIcon } from 'app/components/Icons'
import dayjs from 'dayjs'
import { ArrowCircleLeft } from '@phosphor-icons/react'
import { formatCurrency } from 'helpers/formatCurrency'

const DetailArus = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const arus_kas_code = match.params.arus_kas_code

  //   const [state, setState] = useState({})

  const getData = () => {
    dispatch(getDataArusKasDetail({ arus_kas_code }))
    dispatch(getKategoriArusKas())
  }

  const { dataDetailArusKas, kategori } = useSelector(state => state.arusKas)

  useEffect(() => {
    getData()
    // formatImageUrl(imageUrl)
    // console.log('state.data_checked: ', dataDetailArusKas)
  }, [arus_kas_code])

  // Find the category name based on the code
  const getCategoryName = code => {
    const category = kategori.find(item => item.kategori_arus_kas_code === code)
    return category ? category.kategori_arus_kas_nama : ''
  }
  // console.log(dataDetailArusKas.arus_kas_total)

  return (
    <div className='analytics m-sm-30 mt-7'>
      <div className='mb-sm-30 flex justify-content-between align-items-center'>
        <div className='flex gap-2 align-items-center'>
          <Link to='/arus_kas/' className='back-button'>
            <ArrowCircleLeft size={30} />
          </Link>
          <h1 className='h1-title'>Detail Arus Kas</h1>
        </div>
        <Hidden xsDown>
          <Button
            variant='contained'
            className='p-10 bg-green'
            style={{ textTransform: 'none', color: '#FFFFFF' }}
            onClick={() => history.push('/arus_kas')}
          >
            <span>Kembali</span>
          </Button>
        </Hidden>
      </div>
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Grid container className='mt-2 p-2 ' spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className='fw-bold font-size-40'>Detail Arus Kas</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='image'>
              <h5 className='font-size-14'>Foto Kuitansi</h5>
              {dataDetailArusKas.arus_kas_foto && (
                <InputFileImg
                  noPadding={true}
                  fileName={dataDetailArusKas.arus_kas_foto}
                  preview={dataDetailArusKas.arus_kas_foto}
                  disabled={true}
                />
              )}
            </InputLabel>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='kategori'>
              <h5 className='font-size-14'>Kategori</h5>
            </InputLabel>
            <TextField
              size='small'
              value={getCategoryName(
                dataDetailArusKas.arus_kas_kategori_arus_code
              )}
              name='Kategori'
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                }
              }}
              variant='outlined'
              disabled
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='provinsi'>
              <h5 className='font-size-14'>Tanggal</h5>
            </InputLabel>
            <TextField
              size='small'
              value={dayjs(dataDetailArusKas.arus_kas_tanggal).format(
                'DD/MM/YYYY'
              )}
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                  display: 'flex',
                  alignItems: 'center' // To vertically center the text and icon
                  //   color: '#999' // Set the text color to match the icon color
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <CalendarIcon />
                  </InputAdornment>
                )
              }}
              variant='outlined'
              disabled
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='total'>
              <h5 className='font-size-14'>Total</h5>
            </InputLabel>
            <TextField
              required={true}
              size='small'
              value={`${formatCurrency(dataDetailArusKas.arus_kas_total)}`}
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                }
              }}
              variant='outlined'
              disabled
            />
          </Grid>

          <Grid item lg={6} md={6} xs={false} sm={false}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='detail'>
              <h5 className='font-size-14'>Detail Arus Kas</h5>
            </InputLabel>
            <TextField
              required={true}
              size='small'
              value={dataDetailArusKas.arus_kas_detail}
              name='detail'
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                }
              }}
              multiline
              rows={3}
              variant='outlined'
              type='text'
              disabled
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor='catatan'>
              <h5 className='font-size-14'>Catatan</h5>
            </InputLabel>
            <TextField
              required={true}
              size='small'
              value={dataDetailArusKas.arus_kas_catatan}
              name='catatan'
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                }
              }}
              multiline
              rows={3}
              variant='outlined'
              type='text'
              disabled
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    dataDetailArusKas: state.arusKas.dataArusKas
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDataArusKas: () => dispatch(getDataArusKasDetail())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailArus)
