import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  createStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) =>
  createStyles({
    logo: {
      maxWidth: 188,
      marginBottom: 10,
      marginTop: 10,
    },
    containerForm: {
      padding: "1rem",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 3rem",
      },
    },
    bgPage: {
      backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
      backgroundRepeat: "repeat",
    },
  })
);

const Register = ({ history }) => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [emailErrorText, setEmailErrorText] = React.useState("");
  const [isDataCaleg, setIsDataCaleg] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = useState({
    email: "",
    password: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.setIsDataCaleg(true);
  };

  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        // margin: "0 auto",
      }}
    >
      <Card
        style={
          isDataCaleg
            ? {
                width: "535px",
                // maxHeight: "782px",
                padding: "40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }
            : {
                maxWidth: "535px",
                // maxHeight: "531px",
                padding: "40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }
        }
      >
        {/* {loginErrorText && <Alert severity="error" className="mb-4">{loginErrorText}</Alert>} */}
        <Link to="/">
          <img
            src="/assets/images/logos/logo.png"
            alt="Kenalan"
            className={classes.logo}
          />
        </Link>
        <p style={{ color: "#71BE42" }}>
          <span
            style={{
              color: "white",
              backgroundColor: "#71BE42",
              border: "1px solid #71BE42",
              padding: "0 5px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
            onClick={() => setIsDataCaleg(false)}
            className="cursor-pointer"
          >
            1
          </span>
          <span
            onClick={() => setIsDataCaleg(false)}
            className="cursor-pointer"
          >
            Data Akun{" "}
          </span>
          <span style={isDataCaleg ? { color: "#71BE42" } : { color: "black" }}>
            <span style={{ margin: "0 5px" }}>&gt;</span>
            <span
              style={
                isDataCaleg
                  ? {
                      color: "white",
                      backgroundColor: "#71BE42",
                      border: "1px solid #71BE42",
                      padding: "0 5px",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }
                  : {
                      color: "black",
                      border: "1px solid black",
                      padding: "0 5px",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }
              }
            >
              2
            </span>
            Data Caleg
          </span>
        </p>
        <Grid container spacing={0} justifyContent="center">
          {isDataCaleg == false && (
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <p style={{ fontWeight: "bold", fontSize: "16px" }}>Data Akun</p>
              <Box component="form" autoComplete="off">
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Nama Lengkap
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan nama"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Email
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan email"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <FormHelperText
                    id="component-error-text"
                    className="text-danger"
                  >
                    {emailErrorText}
                  </FormHelperText>
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Username
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan username"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Nomor Ponsel
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan no ponsel"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <Grid container>
                  <CustomButton
                    text={loading ? <CircularProgress /> : "Selanjutnya"}
                    fw={true}
                    // onClick={handleSubmit}
                    onClick={() => setIsDataCaleg(true)}
                    disabled={loading}
                    bgColor="#00ACEE"
                    color="#FFFFFF"
                  />
                </Grid>
                <p className="text-center mt-3" style={{ fontWeight: "bold" }}>
                  Sudah punya akun?{" "}
                  <a href="/login" style={{ color: "#00ACEE" }}>
                    Masuk disini
                  </a>
                </p>
              </Box>
            </Grid>
          )}
          {isDataCaleg == true && (
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <p style={{ fontWeight: "bold", fontSize: "16px" }}>Data Caleg</p>
              <Box component="form" autoComplete="off">
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Nama Lengkap Caleg
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan nama lengkap caleg"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Alamat
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan alamat"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  {/* <FormHelperText id="component-error-text" className="text-danger">
                        {emailErrorText}
                    </FormHelperText> */}
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Daerah Pemilihan
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan daerah pemilihan"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Partai
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan partai"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                  <label
                    htmlFor="outlined-basic"
                    className="mb-2 fw-bold"
                    style={{ fontWeight: "bold" }}
                  >
                    Nomor Urut
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan no urut"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </FormControl>
                <Grid container>
                  <CustomButton
                    text={loading ? <CircularProgress /> : "Daftar"}
                    fw={true}
                    onClick={handleSubmit}
                    disabled={loading}
                    bgColor="#00ACEE"
                    color="#FFFFFF"
                  />
                </Grid>
                <p className="text-center mt-3" style={{ fontWeight: "bold" }}>
                  Sudah punya akun?{" "}
                  <a href="/login" style={{ color: "#00ACEE" }}>
                    Masuk disini
                  </a>
                </p>
              </Box>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default Register;
