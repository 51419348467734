import React from "react";
// import { useDispatch, useSelector } from "react-redux";

import MasterLogistik from "./logistik/MasterLogistik";
import MasterArusKas from "./arus_kas/MasterArusKas";
import MasterPartaiSekarang from "./partai_pemilu_sekarang/MasterPartaiSekarang";
import MasterPartaiTerakhir from "./partai_pemilu_terakhir/MasterPartaiTerakhir";
import MasterKandidat from "./kandidat/MasterKandidat";
import MasterQuickCount from "./quick_count/MasterQuickCount";
// import MasterDataDaerah from "./data_daerah/MasterDataDaerah";

const MasterData = () => {
  return (
    <>
      <MasterPartaiSekarang />
      <MasterPartaiTerakhir />
      <MasterLogistik />
      <MasterArusKas />
      <MasterKandidat />
      <MasterQuickCount />
    </>
  );
};

export default MasterData;
