import { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import React from "react";

export default function MenuActions({ item, editPath, onDelete, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const dispatch = useDispatch();
  // const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // getData();
    setAnchorEl(null);
  };
  // const loadingModal = () => {
  //   Swal.fire({
  //     title: "Sedang diproses...",
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });
  // };

  const handleDelete = () => {
    setAnchorEl(null);
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: "custom-success-button",
        cancelButton: "custom-error-button",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        onDelete(item);
      }
    });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="elevation-z0"
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        // id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to={{
            pathname: editPath,
            state: { itemData: item },
          }}
        >
          <MenuItem
            className="mb-3"
            onClick={handleClose}
            style={{
              width: "163px",
              height: "37px",
              padding: "8px 12px 8px 12px",
              gap: "10px",
              fontWeight: 600,
            }}
          >
            <span>
              <img
                src="assets/images/icons/ic-pencil.svg"
                width={24}
                height={24}
                alt="hapus"
              />
            </span>
            <span className="pl-3" style={{ color: "#71BE42" }}>
              Edit Data
            </span>
          </MenuItem>
        </Link>

        <MenuItem
          className=""
          onClick={handleDelete}
          style={{
            width: "163px",
            height: "37px",
            padding: "8px 12px 8px 12px",
            gap: "10px",
            fontWeight: 600,
          }}
        >
          <span>
            <img
              src="assets/images/icons/ic-trash.svg"
              width={24}
              height={24}
              alt="hapus"
            />
          </span>
          <span className="pl-3" style={{ color: "#E34242" }}>
            Hapus Data
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}
