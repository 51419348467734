import React from "react";
import { Box } from "@mui/material";
import {
  Button,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const AddKecamatan = () => {
  return (
    <>
      <Box className="p-40">
        <div
          className="mb-sm-30"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="font-size-32 fw-700 fw-bold m-0">
            Tambah Nama Kecamatan
          </h2>
          <Button
            variant="contained"
            className="p-10 bg-green"
            style={{
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <span className="">Simpan Data</span>
          </Button>
        </div>

        <Card className="w-100 overflow-auto p-24 mb-3">
          <Grid container className="p-2 " spacing={2}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="provinsi">
                <h5 className="font-size-14">Nama Provinsi</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="provinsi"
                variant="outlined"
                className="w-100"
                name="provinsi"
                displayEmpty
              >
                <MenuItem value="DKI Jakarta">DKI Jakarta</MenuItem>
                <MenuItem value="Jawa Timur">Jawa Timur</MenuItem>
                <MenuItem value="Jawa Tengah">Jawa Tengah</MenuItem>
                <MenuItem value="Sumatera Selatan">Sumatera Selatan</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kota_kab">
                <h5 className="font-size-14">Nama Kota Kabupaten</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kota_kab"
                variant="outlined"
                className="w-100"
                name="kota_kab"
                displayEmpty
              >
                <MenuItem value="Bandung">Bandung</MenuItem>
                <MenuItem value="Tegal">Tegal</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kecamatan">
                <h5 className="font-size-14">Nama Kecamatan</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                name="kecamatan"
                labelid="kecamatan"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Kecamatan"
                variant="outlined"
                type="text"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}></Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default AddKecamatan;
