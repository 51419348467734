import React from "react";
import { Grid } from "@mui/material";

const CardTimeLeft = ({ countdown, timeDesc }) => {
  return (
    <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
      <div className="bg-secondary-50 p-20 text-center">
        <h3 className="fs-1 fw-bold text-green-50">{countdown}</h3>
        <h4 className="fs-6 fw-400">{timeDesc}</h4>
      </div>
    </Grid>
  );
};

export default CardTimeLeft;
