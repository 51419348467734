import React from "react";
import { Box } from "@mui/material";
import { Button, Card, InputLabel, TextField } from "@material-ui/core";

const EditProvinsi = () => {
  return (
    <>
      <Box className="p-40">
        <div
          className="mb-sm-30"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="font-size-32 fw-700 fw-bold m-0">
            Edit Nama Provinsi
          </h2>
          <Button
            variant="contained"
            className="p-10 bg-green"
            style={{
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <span className="">Simpan Data</span>
          </Button>
        </div>

        <Card className="w-100 overflow-auto p-24 mb-3">
          <InputLabel htmlFor="provinsi">
            <h5 className="font-size-14">Nama Provinsi</h5>
          </InputLabel>
          <TextField
            required={true}
            size="small"
            name="provinsi"
            labelid="provinsi"
            className={`w-100`}
            InputProps={{
              style: {
                borderRadius: 5,
              },
            }}
            placeholder="Nama Provinsi"
            variant="outlined"
            type="text"
          />
        </Card>
      </Box>
    </>
  );
};

export default EditProvinsi;
