import {
  GET_TEAM_MANAGEMENT,
  GET_TEAM_MANAGEMENT_LIST,
  GET_DETAIL_TEAM_MANAGEMENT,
} from "../constant.js";

const initialState = {
  dataTeam: [],
  dataTeamList: [
    {
      "team_pemenangan_code": "TP24010803415478845455",
      "team_pemenangan_nama": "Individual"
    },
    {
      "team_pemenangan_code": "TP23090502292882455351",
      "team_pemenangan_nama": "Aditya Erlangga Wibowo123",
    },
    {
      "team_pemenangan_code": "TP23100408145469747445",
      "team_pemenangan_nama": "TIM BIRU BLUE",
    },
    {
      "team_pemenangan_code": "TP23100408142672232905",
      "team_pemenangan_nama": "TIM BIRU MUDA",
    },
    {
      "team_pemenangan_code": "TP24010402082568270185",
      "team_pemenangan_nama": "Tim Budi",
    },
    {
      "team_pemenangan_code": "TP23090604222171340752",
      "team_pemenangan_nama": "Tim Pemenangan Anis",
    },
    {
      "team_pemenangan_code": "TP23092204430214371738",
      "team_pemenangan_nama": "Tim Pemenangan Demokrat",
    },
    {
      "team_pemenangan_code": "TP23092204452936553421",
      "team_pemenangan_nama": "Tim Pemenangan Gelora",
    },
    {
      "team_pemenangan_code": "TP23092204422763286899",
      "team_pemenangan_nama": "Tim Pemenangan Golkar",
    },
    {
      "team_pemenangan_code": "TP23092204433073072319",
      "team_pemenangan_nama": "Tim Pemenangan Hanura",
    },
    {
      "team_pemenangan_code": "TP23092209345780235499",
      "team_pemenangan_nama": "Tim Pemenangan Jokowi",
    },
    {
      "team_pemenangan_code": "TP23092204465278177316",
      "team_pemenangan_nama": "Tim Pemenangan Nasdem",
    },
    {
      "team_pemenangan_code": "TP23092204440875617563",
      "team_pemenangan_nama": "Tim Pemenangan PAN",
    },
    {
      "team_pemenangan_code": "TP23092204414556521611",
      "team_pemenangan_nama": "Tim Pemenangan PDIP",
    },
    {
      "team_pemenangan_code": "TP23092204461955127797",
      "team_pemenangan_nama": "Tim Pemenangan PKS",
    },
    {
      "team_pemenangan_code": "TP23102508290169625131",
      "team_pemenangan_nama": "Tim Pemenangan Prabowo",
    }
  ],
  dataTeamPagination: {},
  detailTeamManagement: {},
};

const TeamManagementReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_MANAGEMENT: {
      return {
        ...state,
        dataTeam: action?.payload.data || [],
        dataTeamPagination: action?.payload.pagination || {}
      };
    }
    case GET_TEAM_MANAGEMENT_LIST: {
      return {
        ...state,
        // dataTeamList: action?.payload.data || [],
      };
    }
    case GET_DETAIL_TEAM_MANAGEMENT: {
      return {
        ...state,
        detailTeamManagement: action?.payload.data || {}
      };
    }
    default: {
      return state;
    }
  }
};

export default TeamManagementReducer;
