import { API, setAuthToken } from '../../../config/API.js';

export function register(params) {
    return API.post('register', params);
}
export function sendVerifyEmail(email, token, expires, bearer) {
    setAuthToken(bearer);
    return API.get(`email/verify/${email}/${token}/${expires}`);
}

export const updateProfile = (params) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/edit/profile", params);
  };