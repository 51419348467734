import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { TabButton } from "app/components";
import {
  Person,
  PersonChecked,
  PersonEdit,
  PersonTwo,
} from "app/components/icon";
import Kandidat from "./Kandidat";
import Pengguna from "./Pengguna/Pengguna";
import KandidatLain from "./KandidatLain/KandidatLain";
import MasterData from "./MasterData/MasterData";
import { useLocation, useHistory } from "react-router-dom";
import NavTabs from "app/components/fragment/NavTabs";
import { User, UserGear, UserPlus, Users } from "@phosphor-icons/react";

const Pengaturan = () => {
  // const location = useLocation();
  // const history = useHistory();

  // const params = new URLSearchParams(location.search);
  // const initialTab = params.get("tab");
  // const [selectedTab, setSelectedTab] = useState(initialTab || "kandidat");

  // useEffect(() => {
  //   history.push(`/pengaturan?tab=${selectedTab}`);
  // }, [selectedTab, history]);

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const initialTab = params.get("tab") || "kandidat";

  const tabs = [
    {
      key: "kandidat",
      title: "Kandidat",
      path: "/pengaturan?tab=kandidat",
      icon: <User style={{ fontSize: 24, marginRight: 5 }} />,
    },
    {
      key: "pengguna",
      title: "Managemen Pengguna",
      path: "/pengaturan?tab=pengguna",
      icon: <UserPlus style={{ fontSize: 24, marginRight: 5 }} />,
    },
    {
      key: "kandidat_lain",
      title: "Kandidat Lain",
      path: "/pengaturan?tab=kandidat_lain",
      icon: <Users style={{ fontSize: 24, marginRight: 5 }} />,
    },
    {
      key: "master_data",
      title: "Master Data",
      path: "/pengaturan?tab=master_data",
      icon: <UserGear style={{ fontSize: 24, marginRight: 5 }} />,
    },
  ];

  return (
    <Fragment>
      <Box className="p-40">
        <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Pengaturan</h2>

        <Card className="w-100 mb-3">
          <NavTabs initialTab={initialTab} history={history} tabs={tabs} />
          {/* <Box className="flex flex-wrap gap-2 p-16">
            <Box className="flex gap-2 md-hidden">
              <Box className="flex flex-wrap flex-grow-1 md-hidden">
                <TabButton
                  selected={selectedTab === "kandidat"}
                  onClick={() => setSelectedTab("kandidat")}
                  title="Kandidat"
                  otherClass="flex-shrink-0"
                  icon={
                    <Person
                      color={selectedTab === "kandidat" ? "#FFFFFF" : "#757575"}
                    />
                  }
                />
              </Box>

              <Box className="flex flex-wrap flex-grow-1 md-hidden">
                <TabButton
                  selected={selectedTab === "pengguna"}
                  onClick={() => setSelectedTab("pengguna")}
                  title="Managemen Pengguna"
                  otherClass="flex-shrink-0"
                  icon={
                    <PersonEdit
                      color={selectedTab === "pengguna" ? "#FFFFFF" : "#757575"}
                    />
                  }
                />
              </Box>

              <Box className="flex flex-wrap flex-grow-1 md-hidden">
                <TabButton
                  selected={selectedTab === "kandidat_lain"}
                  onClick={() => setSelectedTab("kandidat_lain")}
                  title="Kandidat Lainnya"
                  otherClass="flex-shrink-0"
                  icon={
                    <PersonTwo
                      color={
                        selectedTab === "kandidat_lain" ? "#FFFFFF" : "#757575"
                      }
                    />
                  }
                />
              </Box>

              <Box className="flex flex-wrap flex-grow-1 md-hidden">
                <TabButton
                  selected={selectedTab === "master_data"}
                  onClick={() => setSelectedTab("master_data")}
                  title="Master Data"
                  otherClass="flex-shrink-0"
                  icon={
                    <PersonChecked
                      color={
                        selectedTab === "master_data" ? "#FFFFFF" : "#757575"
                      }
                    />
                  }
                />
              </Box>
            </Box>

            <Box className="md-show">
              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap flex-grow-1">
                    <TabButton
                      selected={selectedTab === "kandidat"}
                      onClick={() => setSelectedTab("kandidat")}
                      title="Kandidat"
                      otherClass="flex-grow-1"
                      icon={
                        <Person
                          color={
                            selectedTab === "kandidat" ? "#FFFFFF" : "#757575"
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap flex-grow-1">
                    <TabButton
                      selected={selectedTab === "pengguna"}
                      onClick={() => setSelectedTab("pengguna")}
                      title="Managemen Pengguna"
                      otherClass="flex-grow-1"
                      icon={
                        <PersonEdit
                          color={
                            selectedTab === "pengguna" ? "#FFFFFF" : "#757575"
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap flex-grow-1">
                    <TabButton
                      selected={selectedTab === "kandidat_lain"}
                      onClick={() => setSelectedTab("kandidat_lain")}
                      title="Kandidat Lainnya"
                      otherClass="flex-grow-1"
                      icon={
                        <PersonTwo
                          color={
                            selectedTab === "kandidat_lain"
                              ? "#FFFFFF"
                              : "#757575"
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap flex-grow-1">
                    <TabButton
                      selected={selectedTab === "master_data"}
                      onClick={() => setSelectedTab("master_data")}
                      title="Master Data"
                      otherClass="flex-grow-1"
                      icon={
                        <PersonChecked
                          color={
                            selectedTab === "master_data"
                              ? "#FFFFFF"
                              : "#757575"
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box> */}
        </Card>

        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {initialTab === "kandidat" && <Kandidat />}
              {initialTab === "pengguna" && <Pengguna />}
              {initialTab === "kandidat_lain" && <KandidatLain />}
              {initialTab === "master_data" && <MasterData />}
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
};

export default Pengaturan;
