import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Card, InputLabel, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { addCategoryArusKas } from "app/redux/actions/CategoryArusKasActions";
import HeadTitle from "../components/HeadTitle";
const AddArusKas = () => {
  const history = useHistory();
  const [state, setState] = useState({
    kategori_arus_kas_nama: "",
  });
  const [errorField, setErrorField] = useState({});

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    addCategoryArusKas(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/pengaturan?tab=master_data");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  return (
    <>
      <Box className="p-40">
        <HeadTitle
          title="Tambah Data Kategori Arus Kas"
          onClick={handleSubmit}
          backPath="/pengaturan?tab=master_data"
          btnText="Simpan Data"
        />

        <Card className="w-100 overflow-auto p-24 mb-3">
          <InputLabel htmlFor="kategori_arus_kas_nama">
            <h5 className="font-size-14">Nama Arus Kas</h5>
          </InputLabel>
          <TextField
            required={true}
            size="small"
            name="kategori_arus_kas_nama"
            className={`w-100`}
            value={state.kategori_arus_kas_nama}
            InputProps={{
              style: {
                borderRadius: 5,
              },
            }}
            placeholder="Nama Arus Kas"
            variant="outlined"
            type="text"
            error={errorField?.kategori_arus_kas_nama !== ""}
            helperText={errorField?.kategori_arus_kas_nama}
            onChange={handleChange}
          />
        </Card>
      </Box>
    </>
  );
};

export default AddArusKas;
