import { API, setAuthToken } from "config/API.js";
import { GET_CATEGORY_LOGISTIK } from "../constant.js";

export const getDataCategoryLogistic = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/kategori/logistik?${new URLSearchParams(params)}`)
      .then((res) => {
        dispatch({
          type: GET_CATEGORY_LOGISTIK,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_CATEGORY_LOGISTIK,
          payload: [],
        });
      });
  };
};

export const addCategoryLogistic = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/kategori/logistik/tambah", body);
};

export const updateCategoryLogistic = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/kategori/logistik/update", body);
};

export const deleteCategoryLogistic = (code) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.delete("/user/kategori/logistik/delete/" + code);
};
