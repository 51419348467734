/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Hidden,
  Box,
} from "@material-ui/core";
import { getDataArusKas } from "app/redux/actions/ArusKasActions";
import shortid from "shortid";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import MenuActions from "app/components/button/MenuActions";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Filter } from "app/components/icon";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ModalFilter from "./components/ModalFilter";
import { deleteArusKas } from "app/redux/actions/ArusKasActions";
import { formatCurrency } from "helpers/formatCurrency";
import { useQueryParams } from "helpers/useQueryParams.hook";
import Swal from "sweetalert2";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "app/components/sweetalertService";

const ArusKas = ({ dataArusKas, paginationArusKas }) => {
  const dispatch = useDispatch();

  const { queryParams, setQueryParams } = useQueryParams(
    {
      page: 1,
      limit: 10,
      search: "",
      filter_tanggal: "",
      filter_kategori: "",
      filter_minTotal: "",
      filter_maxTotal: "",
    },
    {
      page: 1,
      limit: 10,
      search: "",
      filter_tanggal: "",
      filter_kategori: "",
      filter_minTotal: "",
      filter_maxTotal: "",
    }
  );
  function handleSearch({
    page,
    limit,
    search,
    filter_tanggal,
    filter_kategori,
    filter_minTotal,
    filter_maxTotal,
  }) {
    setQueryParams({
      ...(page && { page }),
      ...(limit && { limit }),
      ...(search && { search }),
      ...(filter_tanggal && { filter_tanggal }),
      ...(filter_kategori && { filter_kategori }),
      ...(filter_minTotal && { filter_minTotal }),
      ...(filter_maxTotal && { filter_maxTotal }),
    });
  }

  const [state, setState] = useState({
    loading: false,
    page: queryParams.page > 0 ? queryParams.page - 1 : 0,
    rowsPerPageOption: [5, 10, 50, 100],
    paramsChange: false,
  });

  const getData = () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    dispatch(getDataArusKas(queryParams))
      .then(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const getTableRowNumber = (index) => {
    return state.page * queryParams.limit + index + 1;
  };

  const submitSearch = (e) => {
    if (e.keyCode === 13) {
      handleSearch({ ...queryParams, page: 1 });
      updatePagePagination();
    }
  };

  // === START MODAL FILTER ===

  const [modalFilter, setModalFilter] = useState(false);

  const handleClickFilterModal = () => {
    setModalFilter(true);
  };

  const handleSubmitFilter = () => {
    setModalFilter(false);
    setState({ ...state, paramsChange: !state.paramsChange });
  };

  const handleCloseModal = () => {
    setModalFilter(false);
  };

  // === END MODAL FILTER ===

  const setRowsPerPage = (event) => {
    const newLimit = event.target.value;
    handleSearch({ ...queryParams, page: 1, limit: newLimit });
    updatePagePagination();
  };

  const updatePagePagination = () => {
    setState({ ...state, page: 0, paramsChange: !state.paramsChange });
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({
      ...queryParams,
      page: newPage + 1,
      limit: queryParams.limit,
    });
    setState({ ...state, page: newPage, paramsChange: !state.paramsChange });
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };
    handleSearch(updatedSearch);
  };

  const handleSearchChangeTgl = (value) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    const updatedSearch = {
      ...queryParams,
      filter_tanggal: formattedDate,
    };
    handleSearch(updatedSearch);
  };

  const handleDelete = async (code) => {
    const confirmed = await showConfirmation("Hapus", "Apakah kamu yakin ?");

    if (confirmed) {
      try {
        deleteArusKas({ arus_kas_code: code }).then((res) => {
          showSuccess("Data berhasil dihapus!");
          getData();
        });
      } catch (error) {
        showError("Data gagal dihapus !");
      }
    } else {
      showError("Data gagal dihapus !");
    }

    // Swal.fire({
    //   title: "Hapus",
    //   text: "Apakah kamu yakin",
    //   showCancelButton: true,
    //   confirmButtonText: "Yakin",
    //   cancelButtonText: "Batal",
    //   icon: "warning",
    //   buttonsStyling: false,
    //   reverseButtons: true,
    //   customClass: {
    //     confirmButton: "custom-success-button",
    //     cancelButton: "custom-error-button",
    //   },
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     deleteArusKas({ arus_kas_code: code })
    //       .then((res) => {
    //         Swal.fire({
    //           title:
    //             '<div class="custom-swal-title">Data berhasil dihapus</div>',
    //           icon: "success",
    //           buttonsStyling: false,
    //           customClass: {
    //             confirmButton: "custom-success-button",
    //           },
    //         });
    //         getData();
    //       })
    //       .catch((err) => {
    //         Swal.fire({
    //           title: '<div class="custom-swal-title">Data gagal dihapus</div>',
    //           icon: "error",
    //           buttonsStyling: false,
    //           customClass: {
    //             confirmButton: "custom-error-button",
    //           },
    //         });
    //       });
    //   }
    // });
  };

  useEffect(() => {
    getData();
  }, [state.paramsChange, queryParams.search]);

  return (
    <div className="p-40">
      <div className="mb-sm-30">
        <h2 className="font-size-32 fw-700 fw-bold">Arus Kas</h2>
      </div>
      <Card className="w-100 mb-2 p-16" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-2 d-flex justify-content-between align-items-center"
        >
          <Hidden smUp>
            <Grid item xs={12} sm={6} md={6} lg={6} className="">
              <Link to="/arus_kas/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Arus Kas"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} sm={6} md={6} lg={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari"
                  onChange={handleSearchChange}
                  onKeyDown={submitSearch}
                  value={queryParams.search}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3}>
                <GeneralButton
                  variant="outlined"
                  text="Filter"
                  otherClass="text-secondary"
                  border="1px solid #E6E9ED"
                  icon={<Filter />}
                  onClick={handleClickFilterModal}
                />
              </Grid>
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid item xs={3} sm={3} md={3} lg={3} className="flex flex-end">
              <Link to="/arus_kas/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Arus Kas"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
        </Grid>

        <TableContainer>
          <Table className="t-container" style={{ minWidth: 900 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Tanggal</TableCell>
                <TableCell align="center">Kategori</TableCell>
                <TableCell align="center">Detail</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-tbody">
              {state.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : dataArusKas.length > 0 ? (
                dataArusKas.map((item, index) => (
                  <TableRow key={shortid.generate()}>
                    <TableCell className="px-0" align="center">
                      {getTableRowNumber(index)}
                    </TableCell>
                    <TableCell className="px-0" align="center">
                      {dayjs(item.arus_kas_tanggal).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className="px-0 " align="center">
                      {item.kategori_arus_kas_nama}
                    </TableCell>
                    <TableCell className="px-0 " align="center">
                      {item.arus_kas_detail}
                    </TableCell>
                    <TableCell className="px-0 " align="center">
                      {formatCurrency(item.arus_kas_total)}
                    </TableCell>

                    <TableCell align="center" className="">
                      <MenuActions
                        editPath={`/arus_kas/edit/${item.arus_kas_code}`}
                        detailPath={`/arus_kas/${item.arus_kas_code}`}
                        deleteActions={() => handleDelete(item.arus_kas_code)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row" p={1} alignItems="center">
            <Box p={1}>
              <div className="flex">
                <p>Rows per page : </p>
                <select
                  labelid="simple-select"
                  value={queryParams.limit}
                  name="rowsPerPage"
                  onChange={setRowsPerPage}
                  variant="standard"
                  className="border-none"
                >
                  {state.rowsPerPageOption.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </Box>
          </Box>
          <Box>
            <TablePagination
              rowsPerPageOptions={[]}
              className="px-16"
              component="div"
              count={paginationArusKas.total || 0}
              rowsPerPage={queryParams.limit}
              labelRowsPerPage={""}
              page={paginationArusKas.current_page - 1 || 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </Box>
        </Box>
      </Card>

      <ModalFilter
        open={modalFilter}
        handleClickOpen={handleClickFilterModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitFilter}
        state={state}
        setState={setState}
        handleSearchChange={handleSearchChange}
        handleSearchChangeTgl={handleSearchChangeTgl}
        value={queryParams}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataArusKas: state.arusKas.dataArusKas,
    paginationArusKas: state.arusKas.paginationArusKas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataArusKas: () => dispatch(getDataArusKas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArusKas);
