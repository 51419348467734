/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  Button,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { addDapil } from "app/redux/actions/PengaturanActions";
import { getProvince } from "app/redux/actions/ProvinceActions";
import { useDispatch, useSelector } from "react-redux";
import HeadTitle from "../components/HeadTitle";

const AddDapil = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    dapil_nama: "",
    province_code: "",
  });
  const [errorField, setErrorField] = useState({});

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    addDapil(state)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil disimpan</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        history.push("/pengaturan?tab=master_data");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal disimpan!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        setErrorField(err.response.data.data);
      });
  };

  const getData = () => {
    dispatch(getProvince());
  };

  const { dataProvince } = useSelector((state) => state.province);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box className="p-40">
        <HeadTitle
          title="Tambah Data Dapil"
          onClick={handleSubmit}
          backPath="/pengaturan?tab=master_data"
          btnText="Simpan Data"
        />

        <Card className="w-100 overflow-auto p-24 mb-3">
          <Grid
            container
            spacing={3}
            className="mb-4 px-2"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item xs={12} lg={12} md={12} className="p-0">
              <InputLabel htmlFor="dapil_nama">
                <h5 className="font-size-14">Nama Dapil</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                name="dapil_nama"
                className={`w-100`}
                value={state.dapil_nama}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Dapil"
                variant="outlined"
                type="text"
                error={errorField?.dapil_nama !== ""}
                helperText={errorField?.dapil_nama}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12} className="p-0 mt-2">
              <InputLabel htmlFor="province_code">
                <h5 className="font-size-14">Provinsi</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="province_code"
                value={state.province_code}
                onChange={handleChange}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="province_code"
                error={errorField?.province_code !== ""}
                helperText={errorField?.province_code}
                displayEmpty
              >
                <MenuItem value="">Pilih Provinsi</MenuItem>
                {dataProvince.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default AddDapil;
