/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  InputAdornment,
  Icon,
  Hidden,
  TableContainer,
  Box,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrafikRangkumanCaleg,
  getKabupaten,
  getKecamatan,
  getKelurahan,
  getRangkumanCaleg,
  resetKecamatan,
  resetKelurahan,
} from "app/redux/actions/PemiluTerakhirActions";
import BarChart from "../chart/BarChart";
import { useParams } from "react-router-dom";
import { useHistory, Link, useLocation } from "react-router-dom";
import GeneralButton from "app/components/GeneralButton";
import { ArrowCircleLeft } from "@phosphor-icons/react";

const SuaraCaleg = () => {
  const { id_dapil, id_partai } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [state, setState] = useState({
    loading: true,
    kota_kab: "",
    kecamatan: "",
    kelurahan: "",
    caleg: "",
    rangkumanSuaraCaleg: [],
  });
  const color = ["#2EC5FF", "#BBFF92", "#B7EBFF"];
  const { suara_caleg_terbanyak, detailRekapSuara } = location.state;
  // console.log("lokasi", state);

  const getData = () => {
    dispatch(getKabupaten({ dapil: id_dapil }));
    dispatch(getRangkumanCaleg({ dapil: id_dapil }));
    dispatch(getGrafikRangkumanCaleg({ dapil: id_dapil }));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeKabupaten = async (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      kecamatan: "",
      kelurahan: "",
    }));

    await dispatch(resetKecamatan());
    await dispatch(resetKelurahan());
    await dispatch(getKecamatan({ kota_kab: e.target.value }));
    await dispatch(
      getRangkumanCaleg({ dapil: id_dapil, kota_kab: e.target.value })
    );
  };

  const handleChangeKecamatan = async (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      kelurahan: "",
    }));

    await dispatch(resetKelurahan());
    await dispatch(getKelurahan({ kecamatan: e.target.value }));
    await dispatch(
      getRangkumanCaleg({
        dapil: id_dapil,
        kota_kab: state.kota_kab,
        kecamatan: e.target.value,
      })
    );
  };

  const handleChangeKelurahan = async (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    await dispatch(
      getRangkumanCaleg({
        dapil: id_dapil,
        kota_kab: state.kota_kab,
        kecamatan: state.kecamatan,
        kelurahan: e.target.value,
      })
    );
  };

  const {
    kabupaten,
    kecamatan,
    kelurahan,
    rangkumanCalegDetail,
    rangkumanCalegData,
    rangkumanCalegGrafik,
  } = useSelector((state) => state.pemilu_terakhir);

  useEffect(() => {
    const suaraCaleg = suara_caleg_terbanyak.map((item, idx) => ({
      ...item,
      name: item.name,
      value: item.value,
      partai: item.code,
      itemStyle: {
        color: color[idx],
      },
      kota_kab: detailRekapSuara.kota_kab,
      kecamatan: detailRekapSuara.kecamatan,
      kelurahan: detailRekapSuara.kelurahan,
    }));

    setState({
      rangkumanSuaraCaleg: suaraCaleg,
      kota_kab: detailRekapSuara.kota_kab,
      kecamatan: detailRekapSuara.kecamatan,
      kelurahan: detailRekapSuara.kelurahan,
    });
  }, [suara_caleg_terbanyak]);

  return (
    <Box className="p-40">
      <Box className="mb-sm-30">
        <Grid container>
          <Grid item lg={8} md={4} xs={12} sm={6}>
            <Box className="flex gap-2 align-items-center">
              <Link to={`/pemilu_terakhir`} className="back-button">
                <ArrowCircleLeft size={30} />
              </Link>
              <h1 className="fs-2 fw-700">
                Data Suara Caleg Terbanyak {detailRekapSuara?.dapil_nama}
              </h1>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} xs={12} sm={6} container justify="flex-end">
            <Hidden xsDown>
              <GeneralButton
                variant="contained"
                color="secondary"
                text="Kembali"
                otherClass="text-white"
                onClick={() => history.goBack()}
              />
            </Hidden>
          </Grid>
        </Grid>
      </Box>
      <Card
        className="w-100 overflow-auto p-38 pb-0 radius-10 mb-5"
        elevation={6}
      >
        <Grid
          container
          spacing={3}
          className=""
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={12} md={12} className="ps-0 mb-2">
            <h2 className="fs-24 fw-700 fw-600">Rangkuman Caleg</h2>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid
            container
            spacing={3}
            className="mb-4"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kota_kab">
                <h5 className="fs-12 text-muted fw-400">Kota/Kabupaten</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kota_kab"
                value={state.kota_kab}
                onChange={handleChangeKabupaten}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kota_kab"
                displayEmpty
              >
                <MenuItem key={"x"} value="">
                  Pilih Kota
                </MenuItem>
                {kabupaten.map((item) => (
                  <MenuItem value={item.kota_kab_code}>
                    {item.kota_kab}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kecamatan">
                <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kecamatan"
                value={state.kecamatan}
                onChange={handleChangeKecamatan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kecamatan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kecamatan</MenuItem>
                {kecamatan.map((item) => (
                  <MenuItem value={item.kecamatan_code}>
                    {item.kecamatan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={6} className="px-1">
              <InputLabel htmlFor="kelurahan">
                <h5 className="fs-12 text-muted fw-400">Kelurahan</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kelurahan"
                value={state.kelurahan}
                onChange={handleChangeKelurahan}
                variant="outlined"
                className="w-100 d-flex flex-middle p-0"
                name="kelurahan"
                displayEmpty
              >
                <MenuItem value="">Pilih Kelurahan</MenuItem>
                {kelurahan.map((item) => (
                  <MenuItem value={item.kelurahan_code}>
                    {item.kelurahan}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          container
          spacing={3}
          className="mb-4"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Grid container lg={6} md={6} sm={12} xs={12}>
            <Grid item lg={8} md={8} xs={8} sm={8} className="px-2">
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                className="items-center background-white w-full"
                placeholder={"Cari"}
                // onChange={(e) => setSearch(e.target.value)}
                // value={search}
                // onKeyDown={submitSearch}
                name="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Hidden smDown>
            <Grid container lg={6} md={6}>
              <Grid item lg={4} md={4} className="px-2">
                <InputLabel htmlFor="kota_kab">
                  <h5 className="fs-12 text-muted fw-400">Kota/Kabupaten</h5>
                </InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 5,
                    },
                  }}
                  size="small"
                  labelid="kota_kab"
                  value={state.kota_kab}
                  onChange={handleChangeKabupaten}
                  variant="outlined"
                  className="w-100 d-flex flex-middle p-0"
                  name="kota_kab"
                  displayEmpty
                >
                  <MenuItem value="">Pilih Kota</MenuItem>
                  {kabupaten.map((item) => (
                    <MenuItem value={item.kota_kab_code}>
                      {item.kota_kab}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item lg={4} md={4} className="px-2">
                <InputLabel htmlFor="kecamatan">
                  <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
                </InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 5,
                    },
                  }}
                  size="small"
                  labelid="kecamatan"
                  value={state.kecamatan}
                  onChange={handleChangeKecamatan}
                  variant="outlined"
                  className="w-100 d-flex flex-middle p-0"
                  name="kecamatan"
                  displayEmpty
                >
                  <MenuItem value="">Pilih Kecamatan</MenuItem>
                  {kecamatan.map((item) => (
                    <MenuItem value={item.kecamatan_code}>
                      {item.kecamatan}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item lg={4} md={4} className="px-2">
                <InputLabel htmlFor="kelurahan">
                  <h5 className="fs-12 text-muted fw-400">Kelurahan</h5>
                </InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 5,
                    },
                  }}
                  size="small"
                  labelid="kelurahan"
                  value={state.kelurahan}
                  onChange={handleChangeKelurahan}
                  variant="outlined"
                  className="w-100 d-flex flex-middle p-0"
                  name="kelurahan"
                  displayEmpty
                >
                  <MenuItem value="">Pilih Kelurahan</MenuItem>
                  {kelurahan.map((item) => (
                    <MenuItem value={item.kelurahan_code}>
                      {item.kelurahan}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item lg={12} md={12} sm={12} xs={12} className="">
            <TableContainer>
              <Table className="table-res">
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell align="center" colSpan={1}>
                      No
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Nama Caleg
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      Partai
                    </TableCell>
                    {state.kota_kab !== "" && (
                      <TableCell align="left" colSpan={2}>
                        Kota/Kab
                      </TableCell>
                    )}
                    {state.kecamatan !== "" && (
                      <TableCell align="left" colSpan={2}>
                        Kecamatan
                      </TableCell>
                    )}
                    {state.kelurahan !== "" && (
                      <TableCell align="left" colSpan={2}>
                        Kelurahan
                      </TableCell>
                    )}
                    <TableCell align="center" colSpan={2}>
                      Total Suara Caleg
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                      Total Suara Partai
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="t-body">
                  {state.rangkumanSuaraCaleg &&
                    state.rangkumanSuaraCaleg.length > 0 ? (
                    state.rangkumanSuaraCaleg.map((item, index) => (
                      <TableRow
                        key={index}
                      // style={{
                      //   backgroundColor:
                      //     index == 0 || index == 1 ? "#FEE" : "",
                      // }}
                      >
                        <TableCell className="px-0" align="center" colSpan={1}>
                          {index + 1}
                        </TableCell>
                        <TableCell className="px-0" align="left" colSpan={3}>
                          {item.name}
                        </TableCell>
                        <TableCell className="px-0" align="left" colSpan={3}>
                          {item.partai}
                        </TableCell>
                        {state.kota_kab !== "" && (
                          <TableCell
                            className="px-0 capitalize"
                            align="left"
                            colSpan={2}
                          >
                            {item.kota_kab}
                          </TableCell>
                        )}
                        {state.kecamatan !== "" && (
                          <TableCell
                            className="px-0 capitalize"
                            align="left"
                            colSpan={2}
                          >
                            {item.kecamatan}
                          </TableCell>
                        )}
                        {state.kelurahan !== "" && (
                          <TableCell
                            className="px-0 capitalize"
                            align="left"
                            colSpan={2}
                          >
                            {item.kelurahan}
                          </TableCell>
                        )}
                        <TableCell className="px-0" align="center" colSpan={2}>
                          {item.value}
                        </TableCell>
                        <TableCell className="px-0" align="center" colSpan={2}>
                          {item.value}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={
                          (state.kota_kab !== "" ? 2 : 0) +
                          (state.kecamatan !== "" ? 2 : 0) +
                          (state.kelurahan !== "" ? 2 : 0) +
                          10
                        }
                        align="center"
                      >
                        Data kosong
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Card>

      <Card className="w-100 overflow-auto p-38 pb-0 radius-10" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={12} md={12} sm={12} xs={12} className="ps-0">
            <h2 className="fs-24 fw-700 fw-600">Grafik Rangkuman Caleg</h2>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className="ps-0">
            <Box style={{ minWidth: 720 }}>
              <BarChart
                height="350px"
                color={color}
                dataY={state.rangkumanSuaraCaleg}
                dataX={state.rangkumanSuaraCaleg}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default SuaraCaleg;
