// import React, { Component } from "react";
// import {
//   Card,
//   Grid,
//   Button,
//   withStyles,
//   CircularProgress
// } from "@material-ui/core";
// import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
// import { connect } from "react-redux";
// import { PropTypes } from "prop-types";
// import { withRouter } from "react-router-dom";

// import { resetPassword } from "../../redux/actions/LoginActions";

// class ForgotPassword extends Component {
//   state = {
//     email: "watson@example.com"
//   };
//   handleChange = event => {
//     event.persist();
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   };
//   handleFormSubmit = () => {
//     this.props.resetPassword({ ...this.state });
//   };
//   render() {
//     let { email } = this.state;

//     return (
//       <div className="signup flex flex-center w-100 h-100vh">
//         <div className="p-8">
//           <Card className="signup-card position-relative y-center">
//             <Grid container>
//               <Grid item lg={5} md={5} sm={5} xs={12}>
//                 <div className="p-32 flex flex-center flex-middle h-100">
//                   <img src="/assets/images/illustrations/dreamer.svg" alt="" />
//                 </div>
//               </Grid>
//               <Grid item lg={7} md={7} sm={7} xs={12}>
//                 <div className="p-36 h-100 bg-light-gray position-relative">
//                   <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
//                     <TextValidator
//                       className="mb-24 w-100"
//                       variant="outlined"
//                       label="Email"
//                       onChange={this.handleChange}
//                       type="email"
//                       name="email"
//                       value={email}
//                       validators={["required", "isEmail"]}
//                       errorMessages={[
//                         "this field is required",
//                         "email is not valid"
//                       ]}
//                     />
//                     <div className="flex flex-middle">
//                       <Button variant="contained" color="primary" type="submit">
//                         Reset Password
//                       </Button>
//                       <span className="ml-16 mr-8">or</span>
//                       <Button
//                         className="capitalize"
//                         onClick={() =>
//                           this.props.history.push("/session/signin")
//                         }
//                       >
//                         Sign in
//                       </Button>
//                     </div>
//                   </ValidatorForm>
//                 </div>
//               </Grid>
//             </Grid>
//           </Card>
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   resetPassword: PropTypes.func.isRequired,
//   login: state.login
// });
// export default withRouter(
//   connect(
//     mapStateToProps,
//     { resetPassword }
//   )(ForgotPassword)
// );
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  createStyles
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components";
// import { sendVerifyEmail } from "../../redux/actions/AuthActions";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { resetPassword } from "../../redux/actions/LoginActions";

const useStyles = makeStyles(({ palette, ...theme }) =>
createStyles({
  logo: {
    maxWidth: 188,
    marginBottom: 30,
  },
  containerForm: {
    padding: "1rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 3rem",
    },
  },
  bgPage: {
    backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
    backgroundRepeat: "repeat",
  },
})
);

const ForgotPassword = ({ history }) => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [emailErrorText, setEmailErrorText] = React.useState("");
  const [loginErrorText, setLoginErrorText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = useState({
    email: "",
    password: "",
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await setLoading(true);
    if (!params.email) {
      setEmailErrorText("Please enter username");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(params.email)
    ) {
      setEmailErrorText("Invalid email address");
      setLoading(false);
      return;
    } else {
      setEmailErrorText("");
    }
    await setLoading(false);
  };

const classes = useStyles();
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      margin: "0 auto",
    }}
  >
  <Card style={{ maxWidth: "535px", maxHeight: "531px", padding: "40px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Link to="/">
            <img
            src="/assets/images/logos/logo.png"
            alt="Kenalan"
            className={classes.logo}
            />
      </Link>
      <p style={{fontWeight: "bold", fontSize: "16px"}}>Silahkan masukkan email yang anda gunakan untuk mendaftarkan ke situs Kenalan</p>
      
      {loginErrorText && <Alert severity="error" className="mb-4">{loginErrorText}</Alert>}
      <Grid container spacing={0} justifyContent="center">
          <Grid item sm={12} xs={12} lg={12} md={12}>
          <Box component="form" autoComplete="off">
              <FormControl className="w-full mb-4" style={{ width: '100%' }}>
              <label htmlFor="outlined-basic" className="mb-2 fw-bold" style={{fontWeight: "bold"}}>
                  Email
              </label>
              <TextField
                  fullWidth
                  hiddenLabel
                  type="email"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Masukan email"
                  name="email"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
              />
              <FormHelperText id="component-error-text" className="text-danger">
                  {emailErrorText}
              </FormHelperText>
              </FormControl>
              <Grid container>
              <CustomButton
                  text={loading ? <CircularProgress /> : "Kirim"}
                  fw={true}
                  onClick={handleSubmit}
                  disabled={loading}
                  bgColor="#00ACEE"
                  color="#FFFFFF"
                  
              />
              </Grid>
          </Box>
          </Grid>
      </Grid>
  </Card>
 </div>
  );
};

export default ForgotPassword;