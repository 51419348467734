import * as React from "react";
import { Button } from "@material-ui/core";
import { UsersThree } from "@phosphor-icons/react";

const TabButton = ({
  title = "Button",
  onClick = "",
  selected = false,
  icon = "",
  otherClass = "",
}) => {
  return (
    <Button
      className={"p-12 btntab " + (selected ? "active" : "") + " " + otherClass}
      variant={selected ? "contained" : "outlined"}
      onClick={onClick}
    >
      {icon ? icon : <UsersThree style={{ fontSize: 24, marginRight: 5 }} />}
      {title}
    </Button>
  );
};

export default TabButton;
