import { API, setAuthToken } from "config/API.js";
import {
    GET_CATEGORY_ARUS_KAS
} from "../constant.js";


export const getDataCategoryArusKas = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/kategori/aruskas` + params)
            .then((res) => {
                dispatch({
                    type: GET_CATEGORY_ARUS_KAS,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_CATEGORY_ARUS_KAS,
                    payload: [],
                });
            });
    };
};

export const addCategoryArusKas = (body) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/aruskas/tambah", body);
};

export const updateCategoryArusKas = (body) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/aruskas/update", body);
};

export const deleteCategoryArusKas = (body) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/kategori/aruskas/delete", body);
};