import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  TableContainer,
  Icon,
  InputAdornment,
  TextField,
  Chip,
} from "@material-ui/core";
import shortid from "shortid";
import GeneralButton from "app/components/GeneralButton";
import { Link } from "react-router-dom";
import { AddCircleOutline, FilterList } from "@material-ui/icons";
import MenuActions from "./components/MenuActions";
import { deleteLogistik } from "app/redux/actions/LogistikActions";
import Swal from "sweetalert2";

const LaporanTable = ({ data, pagination, getData }) => {
  const [page, setPage] = useState(pagination.current_page - 1 || 0);
  const [rowsPerPage, setRowsPerPage] = useState(pagination.per_page || 10);
  const [search, setSearch] = useState("");

  const rowsPerPageOptions = [10, 50, 100, 500];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(1, rowsPerPage * (newPage + 1));
  };

  const handleSetRowsPerPage = (e) => {
    e.persist();
    setRowsPerPage(e.target.value);

    let limit = e.target.value * page;
    if (page == 0) {
      limit = e.target.value;
    }

    getData(1, limit);
  };

  const getTableRowNumber = (index) => {
    return page * rowsPerPage + index + 1;
  };

  const handleDeleteLaporan = (code) => {
    Swal.fire({
      title: "Hapus",
      text: "Apakah kamu yakin",
      showCancelButton: true,
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
      icon: "warning",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteLogistik(code).then((res) => {
          console.log(res);
        });
      }
    });
  };

  return (
    <div>
      <Grid container>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          className="mb-2"
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Filter"
                  // onChange={(e) => setSearch(e.target.value)}
                  // value={search}
                  name="filter"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterList />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} className="flex flex-end">
            <Link to="/laporan/tambah">
              <GeneralButton
                variant="contained"
                color="primary"
                text="Tambah Laporan"
                icon={<AddCircleOutline fontSize="small" />}
              />
            </Link>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12}>
          <TableContainer>
            <Table className="t-container">
              <TableHead className="t-header">
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="left">Tanggal</TableCell>
                  <TableCell align="center">Waktu</TableCell>
                  <TableCell align="center">Waktu</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Relawan</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="t-body">
                {data.length > 0 ? (
                  data
                    .filter((item) =>
                      item.nama.toLowerCase().includes(search.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={shortid.generate()}>
                        <TableCell className="px-0" align="center">
                          {getTableRowNumber(index)}
                        </TableCell>
                        <TableCell className="px-0" align="left">
                          {item.tanggal || "-"}
                        </TableCell>
                        <TableCell className="px-0" align="center">
                          {item.waktu || "-"}
                        </TableCell>
                        <TableCell className="px-0" align="center">
                          {item.nama || "-"}
                        </TableCell>
                        <TableCell className="px-0" align="center">
                          {item.status == "Selesai" && (
                            <Chip
                              label={"Selesai"}
                              color="primary"
                              style={{ backgroundColor: "#00ACEE" }}
                            />
                          )}
                          {item.status == "Proses" && (
                            <Chip
                              label={"Proses"}
                              color="primary"
                              style={{ backgroundColor: "#E3CD05" }}
                            />
                          )}
                          {item.status == "Baru" && (
                            <Chip
                              label={"Proses"}
                              color="primary"
                              style={{ backgroundColor: "#71BE42" }}
                            />
                          )}
                        </TableCell>
                        <TableCell className="px-0" align="center">
                          {item.relawan}
                        </TableCell>
                        <TableCell className="px-0" align="center">
                          <MenuActions
                            item={item}
                            detailPath={`/laporan/detail/${item.laporan_code}`}
                            editPath={`/laporan/edit/${item.laporan_code}`}
                            deleteAction={() =>
                              handleDeleteLaporan(item.laporan_code)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Data kosong
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Grid container justify="space-between" alignItems="center">
              <div className="flex">
                <p>Rows per page : </p>
                <select
                  labelid="simple-select"
                  value={rowsPerPage}
                  name="rowsPerPage"
                  onChange={handleSetRowsPerPage}
                  variant="standard"
                  className="border-none"
                  disabled={page > 0}
                >
                  {rowsPerPageOptions.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <TablePagination
                className="px-16"
                rowsPerPageOptions={[]}
                component="div"
                count={pagination.total}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={""}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next page",
                }}
                backIconButtonText="Previous page"
                nextIconButtonText="Next page"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </Grid>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default LaporanTable;
