import React, { Fragment, useState } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { TabButton } from "app/components";
import {
  BorderAll,
  CheckCircleOutlineOutlined,
  Sync,
} from "@material-ui/icons";
import { NewsLine } from "app/components/icon";
import LaporanTable from "./LaporanTable";

const Laporan = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Fragment>
      <Box className="p-40">
        <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Laporan</h2>

        <Card className="w-100 mb-4 p-16">
          <Grid className="d-flex gap-24">
            <TabButton
              selected={selectedTab == 0}
              onClick={() => setSelectedTab(0)}
              title="Semua"
              icon={<BorderAll style={{ fontSize: 24, marginRight: 5 }} />}
            />
            <TabButton
              selected={selectedTab == 1}
              onClick={() => setSelectedTab(1)}
              title="Baru"
              icon={
                <NewsLine color={selectedTab == 1 ? "#FFFFFF" : "#757575"} />
              }
            />
            <TabButton
              selected={selectedTab == 2}
              onClick={() => setSelectedTab(2)}
              title="Proses"
              icon={<Sync style={{ fontSize: 24, marginRight: 5 }} />}
            />
            <TabButton
              selected={selectedTab == 3}
              onClick={() => setSelectedTab(3)}
              title="Selesai"
              icon={
                <CheckCircleOutlineOutlined
                  style={{ fontSize: 24, marginRight: 5 }}
                />
              }
            />
          </Grid>
        </Card>

        <LaporanTable
          data={[
            {
              laporan_code: "test",
              tanggal: "test",
              waktu: "test",
              nama: "test",
              status: "Selesai",
              relawan: "test",
              laporan_code: "test",
            },
            {
              laporan_code: "test",
              tanggal: "test",
              waktu: "test",
              nama: "test",
              status: "Proses",
              relawan: "test",
              laporan_code: "test",
            },
            {
              laporan_code: "test",
              tanggal: "test",
              waktu: "test",
              nama: "test",
              status: "Baru",
              relawan: "test",
              laporan_code: "test",
            },
          ]}
          pagination={{
            current_page: 1,
            last_page: 11,
            per_page: "10",
            total: 107,
          }}
          getData={console.log("ab")}
        />
      </Box>
    </Fragment>
  );
};

export default Laporan;
