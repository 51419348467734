import ArusKas from './ArusKas'
import AddArusKas from './AddArusKas'
import EditArusKas from './EditArusKas'
import { Edit } from '@material-ui/icons'
import DetailArusKas from './DetailArusKas'

const ArusKasRoutes = [
  {
    path: '/arus_kas',
    exact: true,
    component: ArusKas
  },
  {
    path: '/arus_kas/tambah',
    exact: true,
    component: AddArusKas
  },
  {
    path: '/arus_kas/edit/:arus_kas_code',
    exact: true,
    component: EditArusKas
  },
  {
    path: '/arus_kas/:arus_kas_code',
    exact: true,
    component: DetailArusKas
  }
]

export default ArusKasRoutes
