import { Grid, Icon, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import CustomTable from "./CustomTable";

const CustomLayout = ({
  title,
  subtitle,
  columns,
  data,
  button,
  addPath,
  search,
  setSearch,
}) => {
  return (
    <>
      <div className="mb-sm-30">
        <h2 className="fw-700 fw-bold mt-2 h1-title">{title}</h2>
      </div>
      <div className="mb-sm-30">
        <h3 className="fw-700 fw-bold mt-2 h2-subtitle">{subtitle}</h3>
      </div>
      <Grid
        container
        spacing={3}
        className="mb-4 d-flex justify-content-between align-items-center"
      >
        <Grid item xs={12} sm={6} lg={9} md={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} lg={6} md={6}>
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                className="items-center background-white w-full"
                placeholder={"Cari"}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                name="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              {/*  */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm className="flex flex-end">
          <Link to={addPath}>
            <GeneralButton
              variant="contained"
              color="primary"
              text={button}
              icon={<AddCircleOutline fontSize="small" />}
            />
          </Link>
        </Grid>
      </Grid>
      <CustomTable columns={columns} data={data} />
    </>
  );
};

export default CustomLayout;
