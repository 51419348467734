/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Hidden } from "@mui/material";
import Card from "@mui/material/Card";
import { Users, UsersThree, Package } from "@phosphor-icons/react";
import {
  getStatisticRelawan,
  getStatisticPemilih,
  getStatisticLogistik,
  getGrafikStatistikPemilihDaily,
  getGrafikStatistikPemilihMonthly,
  getGrafikStatistikRelawanDaily,
  getGrafikStatistikRelawanMonthly,
} from "app/redux/actions/StatisticActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TabButton } from "app/components";
import StatistikRelawan from "./Relawan";
import StatistikPemilih from "./CalonPemilih";
import StatistikLogistik from "./Logistik";

const color = [
  "#71BE42",
  "#00ACEE",
  "#E34242",
  "#8dcb68",
  "#9cd27b",
  "#25c3ff",
  "#41caff",
  "#e96868",
  "#eb7b7b",
  "#ee8e8e",
];

const Statistik = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("monthly");
  const [selectedYear, setSelectedYear] = useState("");

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const initialTab = params.get("tab") || "relawan";

  const { dataStatisticPemilih, dataStatisticLogistik } = useSelector(
    (state) => state.statistic
  );

  const getData = () => {
    dispatch(getStatisticPemilih());
    dispatch(getStatisticRelawan());
    dispatch(getStatisticLogistik());
    dispatch(getGrafikStatistikPemilihDaily());
    dispatch(getGrafikStatistikPemilihMonthly());
    dispatch(getGrafikStatistikRelawanDaily());
    dispatch(getGrafikStatistikRelawanMonthly());
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (initialTab) {
      setSelectedFilter("monthly");
    }
    // getData();
  }, [initialTab]);

  const filterDataGrafik = (data) => {
    let filterTahun = selectedYear ?? "";
    let params = "?tahun=" + filterTahun;
    if (data === "calon_pemilih") {
      if (selectedFilter === "daily") {
        dispatch(getGrafikStatistikPemilihDaily(params));
      } else {
        dispatch(getGrafikStatistikPemilihMonthly(params));
      }
    }

    if (data === "relawan") {
      if (selectedFilter === "daily") {
        dispatch(getGrafikStatistikRelawanDaily(params));
      } else {
        dispatch(getGrafikStatistikRelawanMonthly(params));
      }
    }
  };

  useEffect(() => {
    filterDataGrafik();
  }, []);

  const dataY = dataStatisticLogistik.map((item, index) => ({
    name: item.kategori,
    value: item.total_stok,
    itemStyle: {
      color: color[Math.floor(Math.random() * color.length)],
    },
  }));

  return (
    <Fragment>
      <div className="p-40">
        <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Statistik</h2>

        <Card className="w-100 mb-3">
          <Box className="flex flex-wrap gap-2 p-16">
            <Box className="flex gap-2 xs-hidden">
              <Box className="flex flex-wrap flex-grow-1 xs-hidden">
                <TabButton
                  selected={initialTab === "relawan"}
                  onClick={() => history.push(`statistik?tab=relawan`)}
                  title="Relawan"
                  otherClass="flex-shrink-0"
                  icon={<UsersThree style={{ fontSize: 24, marginRight: 5 }} />}
                />
              </Box>

              <Box className="flex flex-wrap flex-grow-1 xs-hidden">
                <TabButton
                  selected={initialTab === "calon_pemilih"}
                  onClick={() => history.push(`statistik?tab=calon_pemilih`)}
                  title="Calon Pemilih"
                  otherClass="flex-shrink-0"
                  icon={<Users style={{ fontSize: 24, marginRight: 5 }} />}
                />
              </Box>

              <Box className="flex flex-wrap flex-grow-1 xs-hidden">
                <TabButton
                  selected={initialTab === "logistik"}
                  onClick={() => history.push(`statistik?tab=logistik`)}
                  title="Stok Logistik"
                  otherClass="flex-shrink-0"
                  icon={<Package style={{ fontSize: 24, marginRight: 5 }} />}
                />
              </Box>
            </Box>

            <Hidden smUp>
              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap">
                    <TabButton
                      selected={initialTab === "relawan"}
                      onClick={() => history.push(`statistik?tab=relawan`)}
                      title="Relawan"
                      otherClass="flex-grow-1"
                      icon={
                        <UsersThree style={{ fontSize: 24, marginRight: 5 }} />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap">
                    <TabButton
                      selected={initialTab === "calon_pemilih"}
                      onClick={() =>
                        history.push(`statistik?tab=calon_pemilih`)
                      }
                      title="Calon Pemilih"
                      otherClass="flex-grow-1"
                      icon={<Users style={{ fontSize: 24, marginRight: 5 }} />}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} className="mb-2">
                  <Box className="flex flex-wrap">
                    <TabButton
                      selected={initialTab === "logistik"}
                      onClick={() => history.push(`statistik?tab=logistik`)}
                      title="Stok Logistik"
                      otherClass="flex-grow-1"
                      icon={
                        <Package style={{ fontSize: 24, marginRight: 5 }} />
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
          </Box>
        </Card>

        {initialTab === "relawan" && (
          <StatistikRelawan
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSelectedYear={setSelectedYear}
            filterDataGrafik={filterDataGrafik}
          />
        )}

        {initialTab === "calon_pemilih" && (
          <StatistikPemilih
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSelectedYear={setSelectedYear}
            filterDataGrafik={filterDataGrafik}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            {initialTab === "logistik" && (
              <StatistikLogistik
                dataStatisticPemilih={dataStatisticPemilih}
                color={color}
                dataX={dataY}
                dataY={dataY}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default Statistik;
