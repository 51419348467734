import {
  GET_HITUNG_CEPAT,
  GET_DETAIL_HITUNG_CEPAT,
  GET_HITUNG_CEPAT_GRAFIK,
} from "../constant.js";

const initialState = {
  dataHitungCepat: [],
  detailHitungCepat: {},
  dataHitungCepatPagination: {},
  dataGrafikHitungCepat: [
    {
      "grafik_kandidat_nama": "Yusep Maulana",
      "grafik_kandidat_code": "KANDIDAT23083113411837038163",
      "grafik_kandidat_total_suara_caleg": 6700,
      "grafik_kandidat_presentase_suara_caleg": "33.6%",
      "grafik_total_suara_hitung_cepat": 20220
    },
    {
      "grafik_kandidat_nama": "Dr. Ir. H. Sodik Mudjahid, M.Sc",
      "grafik_kandidat_code": "KANDIDAT2310020746099864240",
      "grafik_kandidat_total_suara_caleg": 6184,
      "grafik_kandidat_presentase_suara_caleg": "30.1%",
      "grafik_total_suara_hitung_cepat": 20220
    },
    {
      "grafik_kandidat_nama": "Hj. Ledia Hanifa A, S.Si., M.Psi.T",
      "grafik_kandidat_code": "KANDIDAT2310020840367658470",
      "grafik_kandidat_total_suara_caleg": 7336,
      "grafik_kandidat_presentase_suara_caleg": "36.3%",
      "grafik_total_suara_hitung_cepat": 20220
    }
  ],
};

const HitungCepatReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_HITUNG_CEPAT: {
      return {
        ...state,
        dataHitungCepat: action?.payload.data || [],
        dataHitungCepatPagination: action?.payload.pagination || [],
      };
    }
    case GET_DETAIL_HITUNG_CEPAT: {
      return {
        ...state,
        detailHitungCepat: action?.payload.data || {},
      };
    }
    case GET_HITUNG_CEPAT_GRAFIK: {
      return {
        ...state,
        // dataGrafikHitungCepat: action?.payload || []
      };
    }
    default: {
      return state;
    }
  }
};

export default HitungCepatReducer;
